import { createRouter, createWebHistory } from "vue-router";
import { DateTime } from "luxon";

import api from "@/services/api";

import ProductsListComponent from "../views/ecommerce/ProductsList.vue";

import LogoutComponent from "../views/auth/Logout.vue";

// import EcommerceCalendarComponent from "../views/ecommerce/Calendar.vue";
import SubscriptionComponent from "../views/ecommerce/Subscription.vue";

import EcommerceServiceOrdersComponent from "../views/ecommerce/orders/ServiceOrderList.vue";
import EcommerceProductOrdersComponent from "../views/ecommerce/warehouse/productSales/ProductOrderList.vue";
import EcommerceNewServiceOrderComponent from "../views/ecommerce/orders/NewServiceOrder.vue";
import EcommerceNewProductOrderComponent from "../views/ecommerce/warehouse/products/NewProductOrder.vue";
import certificatesComponent from "../views/ecommerce/Certificates.vue";

import abonementsComponent from "../views/ecommerce/Abonements.vue";
import AddAbonementComponent from "../views/ecommerce/AddAbonement.vue";
import SellAbonementComponent from "../views/ecommerce/SellAbonement.vue";

import EcommerceAnalyticsComponent from "../views/ecommerce/Analytics.vue";
import AnalyticsByStaffComponent from "../views/ecommerce/AnalyticsByStaff.vue";
import AnalyticsByServiceOrdersComponent from "../views/ecommerce/AnalyticsByServiceOrders.vue";

import EcommerceClientsComponent from "../views/ecommerce/clients/clientsdatatable.vue";
import EcommerceClientDetailsComponent from "../views/ecommerce/clients/clientdetails.vue";

import FinancesCashComponent from "../views/pages/finances/cash.vue";

import SettingsCompanyComponent from "../views/pages/settings/Company/CompanyDetails.vue";
import SettingsManagersComponent from "../views/pages/settings/Managers/managers.vue";
import SettingsManagerComponent from "../views/pages/settings/Managers/manager.vue";
import SettingsBranchesComponent from "../views/pages/settings/Branches/branches.vue";
import SettingsBranchComponent from "../views/pages/settings/Branches/branch.vue";

import SettingsPositionsComponent from "../views/pages/settings/Positions/PositionsDatatable.vue";
import SettingsNewPositionComponent from "../views/pages/settings/Positions/NewPosition.vue";

import SettingsServicesComponent from "../views/pages/settings/Services/ServicesDatatable.vue";
import SettingsAdditionalServicesComponent from "../views/pages/settings/Services/AdditionalServicesDatatable.vue";
import SettingsNewAdditionalServicesComponent from "../views/pages/settings/Services/NewAdditionalService.vue";
import SettingsNewServiceComponent from "../views/pages/settings/Services/NewService.vue";

import SettingsStaffComponent from "../views/pages/settings/Staff/StaffDatatable.vue";
import SettingsNewStaffComponent from "../views/pages/settings/Staff/NewStaff.vue";

import TeamSalaryComponent from "../views/pages/Team/Salary.vue";
import TeamSalaryAddSchemaComponent from "../views/pages/Team/SalaryAddSchema.vue";
import TeamSalaryInfoComponent from "../views/pages/Team/SalaryInfo.vue";
import TeamSalaryInfoStaffComponent from "../views/pages/Team/SalaryInfoStaff.vue";

import ChannelManagerComponent from "../views/pages/ChannelManager/ChannelManagerMain.vue";

import SettingsPermissionsComponent from "../views/pages/settings/Permissions.vue";

import SettingsWorkScheduleComponent from "../views/pages/settings/WorkSchedule.vue";

import SettingsRoomsPricesComponent from "../views/ecommerce/RoomPrices.vue";
import SettingsRoomsRestrictionsComponent from "../views/ecommerce/RoomRestrictions.vue";

import OnlineBookingComponent from "../views/pages/settings/onlinebooking/OnlineBooking.vue";
import DiscountsComponent from "../views/pages/settings/Discounts/Discounts.vue";
import AddLongTermDiscountsComponent from "../views/pages/settings/Discounts/AddLongTermDiscount.vue";

import IntegrationsWidgetComponent from "../views/pages/Integrations/Widget.vue";
import IntegrationsAPIComponent from "../views/pages/Integrations/API.vue";
import SMSComponent from "../views/pages/Integrations/SMS.vue";
import SMSpaymentComponent from "../views/pages/Integrations/SMSpayment.vue";
import SMSsettingsComponent from "../views/pages/Integrations/SMSsettings.vue";
import SMSTemplatesComponent from "../views/pages/Integrations/SMSTemplates.vue";
import SMSManualSendsComponent from "../views/pages/Integrations/SMSManualSends.vue";
import SMSHistoryComponent from "../views/pages/Integrations/SMSHistory.vue";
import MonobankComponent from "../views/pages/Integrations/Monobank.vue";
import LiqPayComponent from "../views/pages/Integrations/LiqPay.vue";
import TelegramComponent from "../views/pages/Integrations/Telegram.vue";
import SpaComponent from "../views/pages/Spa.vue";
import IGStoryMakerComponent from "../views/pages/IGStoryMaker.vue";
import TipsComponent from "../views/pages/Integrations/Tips.vue";
import HotelOnboarding from "../views/pages/hotelonboarding/HotelOnboarding.vue";
import BeautyWelcomeOnboardingComponent from "../views/pages/beautyOnboarding/steps/StepWelcome.vue";
import BeautyPositionOnboardingComponent from "../views/pages/beautyOnboarding/steps/StepPosition.vue";
import BeautyStaffOnboardingComponent from "../views/pages/beautyOnboarding/steps/StepStaff.vue";
import BeautyServiceOnboardingComponent from "../views/pages/beautyOnboarding/steps/StepService.vue";
import BeautySuccessOnboardingComponent from "../views/pages/beautyOnboarding/steps/Success.vue";

import EditProfileComponent from "../views/pages/account/EditProfile.vue";

import MaintenanceMigrationsComponent from "../views/pages/maintenance/Migrations.vue";
import MaintenanceCronComponent from "../views/pages/maintenance/Cron.vue";
import MaintenanceTenantsComponent from "../views/pages/maintenance/Tenants.vue";

import SuppliersListComponent from "../views/ecommerce/warehouse/suppliers/SuppliersDatatable.vue";
import NewSupplierComponent from "../views/ecommerce/warehouse/suppliers/NewSupplier.vue";
import NewSupplyComponent from "../views/ecommerce/warehouse/supply/NewSupply.vue";
import SuppliesDatatableComponent from "../views/ecommerce/warehouse/supply/SuppliesDatatable.vue";
import NewProductComponent from "../views/ecommerce/warehouse/products/NewProduct.vue";
import ApplicationJournalComponent from "../views/pages/account/Journal.vue";

import ReviewsSettingsComponent from "../views/pages/Reviews/ReviewsSettings.vue";
import ReviewsOverviewComponent from "../views/pages/Reviews/ReviewsOverview.vue";
import EditReviewComponent from "../views/pages/Reviews/EditReview.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/ecommerce/calendar",
  },
  // {
  //   path: "/ecommerce/calendar-test",
  //   name: "calendar test",
  //   component: () => import("@/views/ecommerce/Calendar.vue"),
  // },

  {
    path: "/ecommerce/products/products-list",
    name: "Products List",
    component: ProductsListComponent,
  },

  {
    path: "/authentication/logout",
    name: "Вихід",
    component: LogoutComponent,
  },
  {
    path: "/ecommerce/calendar",
    name: "Календар",
    component: () => import("@/views/ecommerce/CalendarTest.vue"),
  },
  {
    path: "/ecommerce/subscription",
    name: "Підписка",
    component: SubscriptionComponent,
  },

  {
    path: "/ecommerce/service-orders",
    name: "Записи",
    component: EcommerceServiceOrdersComponent,
  },
  {
    path: "/ecommerce/service-orders/new",
    name: "Створити запис",
    component: EcommerceNewServiceOrderComponent,
  },
  {
    path: "/ecommerce/service-orders/:id",
    name: "Деталі запису",
    component: EcommerceNewServiceOrderComponent,
  },
  {
    path: "/certificates",
    name: "Сертифікати",
    component: certificatesComponent,
  },
  {
    path: "/abonements",
    name: "Абонементи",
    component: abonementsComponent,
  },
  {
    path: "/add-abonement",
    name: "Створити абонемент",
    component: AddAbonementComponent,
  },
  {
    path: "/sell-abonement",
    name: "Продати абонемент",
    component: SellAbonementComponent,
  },

  {
    path: "/warehouse/product-orders",
    name: "Продажі",
    component: EcommerceProductOrdersComponent,
  },
  {
    path: "/warehouse/orders/new-product-order",
    name: "Створити продаж",
    component: EcommerceNewProductOrderComponent,
  },
  {
    path: "/analytics",
    name: "Аналітика",
    component: EcommerceAnalyticsComponent,
  },
  {
    path: "/analytics/bystaff",
    component: AnalyticsByStaffComponent,
  },
  {
    path: "/analytics/byserviceorders",
    component: AnalyticsByServiceOrdersComponent,
  },

  {
    path: "/ecommerce/clients",
    name: "Клієнти",
    component: EcommerceClientsComponent,
  },
  {
    path: "/ecommerce/clients/new",
    name: "Створити клієнта",
    component: EcommerceClientDetailsComponent,
  },
  {
    path: "/ecommerce/clients/:id",
    name: "details_client",
    component: EcommerceClientDetailsComponent,
  },
  {
    path: "/finances/cash",
    name: "Фінанси",
    component: FinancesCashComponent,
  },
  {
    path: "/settings/company",
    name: "Налаштування компанії",
    component: SettingsCompanyComponent,
  },
  {
    path: "/team/managers/",
    name: "Адміністратори",
    component: SettingsManagersComponent,
  },
  {
    path: "/team/managers/new",
    name: "Створити адміністратора",
    component: SettingsManagerComponent,
  },
  {
    path: "/team/managers/:id",
    name: "Редагувати адміністратора",
    component: SettingsManagerComponent,
  },
  {
    path: "/team/positions",
    name: "Посади",
    component: SettingsPositionsComponent,
  },
  {
    path: "/team/positions/new",
    name: "Створити посаду",
    component: SettingsNewPositionComponent,
  },
  {
    path: "/team/positions/:id",
    name: "Редагувати посаду",
    component: SettingsNewPositionComponent,
  },
  {
    path: "/settings/services",
    name: "Послуги",
    component: SettingsServicesComponent,
  },
  {
    path: "/settings/additional-services",
    name: "Додаткові послуги",
    component: SettingsAdditionalServicesComponent,
  },
  {
    path: "/settings/additional-services/new",
    name: "Нова додаткова послуга",
    component: SettingsNewAdditionalServicesComponent,
  },
  {
    path: "/settings/additional-services/:id",
    name: "Редагувати додаткову послугу",
    component: SettingsNewAdditionalServicesComponent,
  },
  {
    path: "/settings/services/new",
    name: "Нова послуга",
    component: SettingsNewServiceComponent,
  },
  {
    path: "/settings/services/:id",
    name: "Редагувати послугу",
    component: SettingsNewServiceComponent,
  },
  {
    path: "/team/staff",
    name: "Майстри",
    component: SettingsStaffComponent,
  },
  {
    path: "/team/staff/new",
    name: "Створити майстра",
    component: SettingsNewStaffComponent,
  },
  {
    path: "/team/staff/:id",
    name: "Редагувати майстра",
    component: SettingsNewStaffComponent,
  },
  {
    path: "/team/salary/",
    name: "Зарплатня",
    component: TeamSalaryComponent,
  },
  {
    path: "/team/salary-addschema/",
    name: "Зарплатня - створити схему",
    component: TeamSalaryAddSchemaComponent,
  },
  {
    path: "/team/salaryinfo/",
    name: "Зарплатня - деталі",
    component: TeamSalaryInfoComponent,
  },
  {
    path: "/team/salaryinfostaff/",
    name: "Зарплатня - деталі",
    component: TeamSalaryInfoStaffComponent,
  },

  {
    path: "/channel-manager",
    name: "Менеджер каналів - головна",
    component: ChannelManagerComponent,
  },

  {
    path: "/settings/permissions",
    name: "Доступи",
    component: SettingsPermissionsComponent,
  },
  {
    path: "/ecommerce/rooms-prices",
    name: "Ціни номерів",
    component: SettingsRoomsPricesComponent,
  },
  {
    path: "/ecommerce/restrictions",
    name: "Заборони",
    component: SettingsRoomsRestrictionsComponent,
  },

  {
    path: "/team/workschedule",
    name: "Графік роботи",
    component: SettingsWorkScheduleComponent,
  },

  {
    path: "/settings/online-booking",
    name: "Онлайн запис",
    component: OnlineBookingComponent,
  },

  {
    path: "/settings/discounts",
    name: "Знижки",
    component: DiscountsComponent,
  },
  {
    path: "/settings/discounts/new",
    name: "Знижки на довгострокове перебування - створити",
    component: AddLongTermDiscountsComponent,
  },
  {
    path: "/settings/discounts/:id",
    name: "Знижки на довгострокове перебування - редагувати",
    component: AddLongTermDiscountsComponent,
  },

  {
    path: "/integrations/widget",
    name: "Widget на сайт",
    component: IntegrationsWidgetComponent,
  },
  {
    path: "/integrations/api",
    name: "API",
    component: IntegrationsAPIComponent,
  },
  {
    path: "/team/editprofile",
    name: "Редагувати профіль",
    component: EditProfileComponent,
  },
  {
    path: "/pages/journal",
    name: "Журнал",
    component: ApplicationJournalComponent,
  },
  {
    path: "/reviews-settings",
    component: ReviewsSettingsComponent,
  },
  {
    path: "/reviews-overview",
    name: "ReviewListPage",
    component: ReviewsOverviewComponent,
  },
  {
    path: "/edit-review/:feedbackId",
    name: "ReviewPage",
    component: EditReviewComponent,
    props: true
  },

  {
    path: "/warehouse/products-list",
    name: "Товари",
    component: ProductsListComponent,
  },
  {
    path: "/warehouse/suppliers",
    name: "Постачальники",
    component: SuppliersListComponent,
  },
  {
    path: "/warehouse/new-supplier",
    name: "Створити постачальника",
    component: NewSupplierComponent,
  },
  {
    path: "/warehouse/supply",
    name: "Створити поставку",
    component: NewSupplyComponent,
  },
  {
    path: "/warehouse/suppliesdatatable",
    name: "Поставки",
    component: SuppliesDatatableComponent,
  },
  {
    path: "/warehouse/newproduct",
    name: "Створити продукт",
    component: NewProductComponent,
  },
  {
    path: "/settings/branches",
    name: "Філії",
    component: SettingsBranchesComponent,
  },
  {
    path: "/settings/branches/new",
    name: "Створити філію",
    component: SettingsBranchComponent,
  },
  {
    path: "/settings/branches/:id",
    name: "Редагувати філію",
    component: SettingsBranchComponent,
  },
  {
    path: "/integrations/sms",
    name: "Інтеграція SMS",
    component: SMSComponent,
  },
  {
    path: "/integrations/sms-payment",
    name: "Поповнення SMS",
    component: SMSpaymentComponent,
  },
  {
    path: "/hotel/onboarding/create-unit",
    name: "Створити кімнату",
    component: HotelOnboarding,
  },
  {
    path: "/beauty/onboarding/welcome",
    name: "",
    component: BeautyWelcomeOnboardingComponent,
  },
  {
    path: "/beauty/onboarding/add-position",
    name: "",
    component: BeautyPositionOnboardingComponent,
  },
  {
    path: "/beauty/onboarding/add-staff",
    name: "",
    component: BeautyStaffOnboardingComponent,
  },
  {
    path: "/beauty/onboarding/add-service",
    name: "",
    component: BeautyServiceOnboardingComponent,
  },
  {
    path: "/beauty/onboarding/success",
    name: "",
    component: BeautySuccessOnboardingComponent,
  },

  {
    path: "/integrations/sms-settings",
    name: "Налаштування SMS",
    component: SMSsettingsComponent,
  },
  {
    path: "/integrations/sms-templates",
    name: "Шаблони SMS",
    component: SMSTemplatesComponent,
  },
  {
    path: "/integrations/sms-manual-sends",
    name: "SMS розсилки",
    component: SMSManualSendsComponent,
  },
  {
    path: "/integrations/sms-history",
    name: "Історія SMS",
    component: SMSHistoryComponent,
  },
  {
    path: "/integrations/telegram",
    name: "Інтеграція Telegram",
    component: TelegramComponent,
  },
  {
    path: "/spa",
    name: "SPA",
    component: SpaComponent,
  },
  {
    path: "/ig-story-maker",
    component: IGStoryMakerComponent,
  },
  {
    path: "/integrations/monobank",
    name: "Monobank Еквайринг",
    component: MonobankComponent,
  },
  {
    path: "/integrations/liqpay",
    name: "LiqPay Еквайринг",
    component: LiqPayComponent,
  },
  {
    path: "/integrations/tips",
    name: "Чайові",
    component: TipsComponent,
  },

  {
    path: "/maintenance/migrations",
    name: "maintenance - міграції",
    component: MaintenanceMigrationsComponent,
  },
  {
    path: "/maintenance/cron",
    name: "maintenance - cron",
    component: MaintenanceCronComponent,
  },
  {
    path: "/maintenance/tenants",
    name: "maintenance - tenants",
    component: MaintenanceTenantsComponent,
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

let forcePaymentEnabledLastCheckAtDate = DateTime.now()
  .minus({ days: 1 })
  .toISODate();

const forcePaymentEnabledCheck = async () => {
  const currentDate = DateTime.now().toISODate();
  if (currentDate === forcePaymentEnabledLastCheckAtDate) {
    return;
  }

  const company = await api.company.show();
  if (company.forcePaymentEnabled) {
    window.location.href = "/ecommerce/subscription";
    return;
  }

  forcePaymentEnabledLastCheckAtDate = currentDate;
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  if (
    to.path !== "/ecommerce/subscription" &&
    to.path !== "/settings/company" &&
    to.path !== "/authentication/logout"
    // !rootNumbers.includes(decoded.phoneNumber)
  ) {
    await forcePaymentEnabledCheck();
  }

  return next();
});

export default router;
