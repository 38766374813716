<template>
  <!-- NOW MVP -->
  <div class="wrapper-page-analytics container-fluid py-4" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="wrapper-page-analytics container-fluid py-4" v-show="dataLoaded">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="dropdown d-inline">
            <a
              href="javascript:;"
              class="btn btn-sm btn-outline-dark dropdown-toggle"
              data-bs-toggle="dropdown"
              id="navbarDropdownMenuLink2"
            >
              🗓 {{ displaySelectedDateRange() }}
            </a>
            <ul
              class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownMenuLink2"
              data-popper-placement="left-start"
            >
              <li v-for="(dateRange, index) in listDateRanges" :key="index">
                <button
                  class="dropdown-item border-radius-md"
                  @click="filterByDateRange(dateRange._id)"
                >
                  {{ dateRange.name }}
                </button>
              </li>
              <li>
                <hr class="horizontal dark my-2" />
              </li>
            </ul>
          </div>
        </div>
        <div class="col" v-show="!_isHotel()">
          <div class="dropdown d-inline">
            <a
              href="javascript:;"
              class="btn btn-sm btn-outline-dark dropdown-toggle"
              data-bs-toggle="dropdown"
              id="navbarDropdownMenuLink2"
            >
              {{ displaySelectedPosition() }}
            </a>
            <ul
              class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownMenuLink2"
              data-popper-placement="left-start"
            >
              <li v-for="(position, index) in listPositions" :key="index">
                <button
                  class="dropdown-item border-radius-md"
                  @click="filterByPositionId(position._id)"
                >
                  {{ position.name }}
                </button>
              </li>
              <li>
                <hr class="horizontal dark my-2" />
              </li>
            </ul>
          </div>
        </div>
        <div class="col">
          <div class="dropdown d-inline">
            <a
              href="javascript:;"
              class="btn btn-sm btn-outline-dark dropdown-toggle"
              data-bs-toggle="dropdown"
              id="navbarDropdownMenuLink2"
            >
              {{ displaySelectedStaff() }}
            </a>
            <ul
              class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownMenuLink2"
              data-popper-placement="left-start"
            >
              <li v-for="(singleStaff, index) in listStaff" :key="index">
                <button
                  class="dropdown-item border-radius-md"
                  :class="{ 'bmn-staff-removed': singleStaff.isRemoved }"
                  href="javascript:;"
                  @click="filterByStaffId(singleStaff._id)"
                >
                  {{ singleStaff.name }}
                  <span v-if="singleStaff.positionId"
                    >({{ singleStaff.positionId.name }})</span
                  >
                  <span v-if="singleStaff._id && !singleStaff.isActive">
                    ❌
                  </span>
                </button>
              </li>
              <li>
                <hr class="horizontal dark my-2" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row"></div>
    <div class="row" v-show="selectedDateRange === 'custom'">
      <div class="col-lg-3 col-sm-12 text-end">
        <flat-pickr
          v-model="customSelectedDateRange"
          id="calendarFlatpickr"
          class="form-control datetimepicker"
          :config="calendarConfig"
          ref="datePickerWrap"
        ></flat-pickr>
        <div class="mb-4"></div>
      </div>
    </div>
    <div class="row">
      <h5>
        Гроші
        <div class="badge badge-info badge-sm mx-3">
          {{ computedSelectedDateRange }}
        </div>
      </h5>
    </div>
    <div class="row mt-3">
      <div class="col-6 col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-2">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <div
              class="icon icon-sm icon-shape bg-gradient-success shadow-success text-center border-radius-md mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">payment</i>
            </div>
            <div class="text-end">
              <p class="text-sm mb-0">
                Дохід
                <a
                  href="javascript:;"
                  class="mx-3"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Сумується надходження готівкових та безготівкових коштів за броні із статусом 'Клієнт відвідав'."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </p>
              <h4 class="mb-0">{{ metrics.serviceOrdersIncomeSum }} грн</h4>
            </div>
          </div>
          <hr class="horizontal my-0 dark" />
        </div>
      </div>
      <div class="col-6 col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-2">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <div
              class="icon icon-sm icon-shape bg-gradient-success shadow-success text-center border-radius-md mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">payment</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0">
                Середній чек
                <a
                  href="javascript:;"
                  class="mx-3"
                  v-if="company.crmMode !== 'hotel'"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="'Виручка за послуги' за період ділиться на кількість записів за період зі статусом 'Клієнт прийшов'"
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
                <a
                  href="javascript:;"
                  class="mx-3"
                  v-if="company.crmMode === 'hotel'"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="'Виручка за послуги' за період ділиться на кількість бронювань за період зі статусом 'Клієнт відвідав'"
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </p>
              <h4 class="mb-0">{{ metrics.serviceOrdersIncomeMean }} грн</h4>
            </div>
          </div>
          <hr class="horizontal my-0 dark" />
        </div>
      </div>
      <div class="col-6 col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-2">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <div
              class="icon icon-sm icon-shape bg-gradient-success shadow-success text-center border-radius-md mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">payment</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0">
                Очікує на оплату
                <a
                  href="javascript:;"
                  class="mx-3"
                  v-if="company.crmMode !== 'hotel'"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Гроші, які ще несплачені і очікуються за записи в обраному періоді. Записи в статусі ʼВідміненоʼ не враховуються."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
                <a
                  href="javascript:;"
                  class="mx-3"
                  v-if="company.crmMode === 'hotel'"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Гроші, які ще несплачені і очікуються за бронювання в обраному періоді. Броні в статусі ʼВідміненоʼ не враховуються."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </p>
              <h4 class="mb-0">
                {{ metrics.serviceOrdersFutureIncome || 0 }} грн
              </h4>
            </div>
          </div>
          <hr class="horizontal my-0 dark" />
        </div>
      </div>
    </div>
    <hr />
    <div class="row mt-5">
      <h5 v-if="company.crmMode !== 'hotel'">
        Записи
        <div class="badge badge-info badge-sm mx-3">
          {{ computedSelectedDateRange }}
        </div>
      </h5>
      <h5 v-if="company.crmMode === 'hotel'">
        Броні
        <div class="badge badge-info badge-sm mx-3">
          {{ computedSelectedDateRange }}
        </div>
      </h5>
    </div>
    <div class="row mt-3">
      <div class="col-6 col-lg-3 col-md-6 col-sm-6 mt-lg-0">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <div
              class="icon icon-sm icon-shape bg-gradient-info shadow-info text-center border-radius-md mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">event</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0">
                Всього
                <a
                  href="javascript:;"
                  class="mx-3"
                  v-if="company.crmMode === 'hotel'"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Кількість бронювань за період."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
                <a
                  href="javascript:;"
                  class="mx-3"
                  v-if="company.crmMode !== 'hotel'"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Кількість записів за період."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </p>
              <h4 class="mb-0">{{ metrics.serviceOrdersCountersTotal }}</h4>
            </div>
          </div>
          <hr class="horizontal my-0 dark" />
        </div>
      </div>
      <div class="col-6 col-lg-3 col-md-6 col-sm-6 mt-lg-0">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <div
              class="icon icon-sm icon-shape bg-gradient-info shadow-info text-center border-radius-md mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">event</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0">
                Відмінені
                <a
                  href="javascript:;"
                  class="mx-3"
                  v-if="company.crmMode === 'hotel'"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Кількість бронювань зі статусом 'Відмінено'."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
                <a
                  href="javascript:;"
                  class="mx-3"
                  v-if="company.crmMode !== 'hotel'"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Кількість записів зі статусом 'Відмінено'."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </p>
              <h4 class="mb-0">
                {{ metrics.serviceOrdersCountersCancelled }}
              </h4>
            </div>
          </div>
          <hr class="horizontal my-0 dark" />
        </div>
      </div>
      <div class="col-6 col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <div
              class="icon icon-sm icon-shape bg-gradient-info shadow-info text-center border-radius-md mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">event</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0">
                Виконані
                <a
                  href="javascript:;"
                  class="mx-3"
                  v-if="company.crmMode === 'hotel'"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Кількість бронювань зі статусом 'Клієнт відвідав'."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
                <a
                  href="javascript:;"
                  class="mx-3"
                  v-if="company.crmMode !== 'hotel'"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Кількість записів зі статусом 'Клієнт прийшов'."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </p>
              <h4 class="mb-0">{{ metrics.serviceOrdersCountersDone }}</h4>
            </div>
          </div>
          <hr class="horizontal my-0 dark" />
        </div>
      </div>
      <div class="col-6 col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4 mb-3">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <div
              class="icon icon-sm icon-shape bg-gradient-info shadow-info text-center border-radius-md mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">event</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0">
                Незавершених
                <a
                  href="javascript:;"
                  class="mx-3"
                  v-if="company.crmMode === 'hotel'"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Кількість бронювань зі статусом 'Нова'."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
                <a
                  href="javascript:;"
                  class="mx-3"
                  v-if="company.crmMode !== 'hotel'"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Кількість записів зі статусом 'Нова'."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </p>
              <h4 class="mb-0">{{ metrics.serviceOrdersCountersNew }}</h4>
            </div>
          </div>
          <hr class="horizontal my-0 dark" />
        </div>
      </div>
    </div>
    <hr />
    <div class="row mt-5" v-if="showDevFeatures">
      <h5>
        DEV За добами
        <div class="badge badge-info badge-sm mx-3">
          {{ computedSelectedDateRange }}
        </div>
      </h5>
    </div>
    <div class="row mt-3" v-if="showDevFeatures">
      <div class="col-6 col-lg-3 col-md-6 col-sm-6 mt-lg-0">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <div
              class="icon icon-sm icon-shape bg-gradient-info shadow-success text-center border-radius-md mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">event</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0">
                Всього
                <a
                  href="javascript:;"
                  class="mx-3"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Кількість забронювань діб за період"
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </p>
              <h4 class="mb-0">{{ metrics.serviceOrdersCountersTotal }}</h4>
            </div>
          </div>
          <hr class="horizontal my-0 dark" />
        </div>
      </div>
      <div class="col-6 col-lg-3 col-md-6 col-sm-6 mt-lg-0">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <div
              class="icon icon-sm icon-shape bg-gradient-info shadow-success text-center border-radius-md mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">event</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0">
                Відмінені
                <a
                  href="javascript:;"
                  class="mx-3"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Кількість забронюваних діб зі статусом 'Відмінено'."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </p>
              <h4 class="mb-0">
                {{ metrics.serviceOrdersCountersCancelled }}
              </h4>
            </div>
          </div>
          <hr class="horizontal my-0 dark" />
        </div>
      </div>
      <div class="col-6 col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <div
              class="icon icon-sm icon-shape bg-gradient-info shadow-info text-center border-radiusmdl mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">event</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0">
                Виконані
                <a
                  href="javascript:;"
                  class="mx-3"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Кількість бронювань зі статусом 'Клієнт відвідав'."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </p>
              <h4 class="mb-0">{{ metrics.serviceOrdersCountersDone }}</h4>
            </div>
          </div>
          <hr class="horizontal my-0 dark" />
        </div>
      </div>
      <div class="col-6 col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4 mb-3">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <div
              class="icon icon-sm icon-shape bg-gradient-info shadow-info text-center border-radius-md mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">event</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0">
                Незавершених
                <a
                  href="javascript:;"
                  class="mx-3"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Кількість забронюваних діб у статусі 'Нова'."
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </p>
              <h4 class="mb-0">{{ metrics.serviceOrdersCountersNew }}</h4>
            </div>
          </div>
          <hr class="horizontal my-0 dark" />
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-lg-3 col-sm-6 mt-2">
        <div class="card h-100">
          <div class="card-header pb-0 p-3">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">Канали бронювань</h6>
              <a
                href="javascript:;"
                class="mx-3"
                v-if="company.crmMode === 'hotel'"
                data-bs-toggle="tooltip"
                data-bs-original-title="Враховуються всі створені бронювання незалежно від статусу."
              >
                <i
                  class="material-icons text-secondary position-relative text-lg"
                  >info</i
                >
              </a>
              <a
                href="javascript:;"
                class="mx-3"
                v-if="company.crmMode !== 'hotel'"
                data-bs-toggle="tooltip"
                data-bs-original-title="Враховуються всі створені записи незалежно від статусу."
              >
                <i
                  class="material-icons text-secondary position-relative text-lg"
                  >info</i
                >
              </a>
            </div>
          </div>
          <div class="card-body pb-0 p-3 mt-4">
            <div class="row">
              <div class="col-12 text-start">
                <div
                  class="chart"
                  v-if="
                    charts.bookingsByChannel.data?.datasets[0]?.data?.length
                  "
                >
                  <Pie
                    :chart-data="charts.bookingsByChannel.data"
                    :chart-options="charts.bookingsByChannel.options"
                  />
                </div>

                <div
                  class="parent-container-no-data"
                  v-if="
                    !charts.bookingsByChannel.data?.datasets[0]?.data?.length
                  "
                >
                  <div class="vertical-center">Немає даних</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 mt-2">
        <div class="card h-100">
          <div class="card-header pb-0 p-3">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">{{ getLabelPieByStaff() }}</h6>
              <a
                href="javascript:;"
                v-if="company.crmMode === 'hotel'"
                class="mx-3"
                data-bs-toggle="tooltip"
                data-bs-original-title="Враховуються всі створені бронювання незалежно від статусу."
              >
                <i
                  class="material-icons text-secondary position-relative text-lg"
                  >info</i
                >
              </a>
              <a
                href="javascript:;"
                v-if="company.crmMode !== 'hotel'"
                class="mx-3"
                data-bs-toggle="tooltip"
                data-bs-original-title="Враховуються всі створені записи незалежно від статусу."
              >
                <i
                  class="material-icons text-secondary position-relative text-lg"
                  >info</i
                >
              </a>
            </div>
          </div>
          <div class="card-body pb-0 p-3 mt-4">
            <div class="col-12 text-start">
              <div
                class="chart"
                v-if="charts.bookingsByStaff.data?.labels?.length"
              >
                <Pie
                  :chart-data="charts.bookingsByStaff.data"
                  :chart-options="charts.bookingsByStaff.options"
                />
              </div>

              <div
                class="parent-container-no-data"
                v-if="!charts.bookingsByStaff.data?.labels?.length"
              >
                <div class="vertical-center">Немає даних</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row mt-5">
      <h5>Клієнти</h5>
      <div>
        <flat-pickr
          v-model="clientsPeriod"
          id="calendarFlatpickrClients"
          class=""
          :config="calendarConfigClients"
          ref="datePickerWrapClients"
        ></flat-pickr>
      </div>
    </div>
    <div class="col-6col-lg-12 position-relative z-index-2 mt-2">
      <div class="row">
        <div class="col-lg-2">
          <div class="card">
            <div class="card-body p-3 position-relative">
              <div class="row">
                <div class="col-12 text-start">
                  <p class="text-sm mb-1 font-weight-bold">
                    Всього клієнтів
                    <a
                      href="javascript:;"
                      class="mx-3"
                      data-bs-toggle="tooltip"
                      data-bs-original-title="Всього клієнтів за весь період."
                    >
                      <i
                        class="material-icons text-secondary position-relative text-lg"
                        >info</i
                      >
                    </a>
                  </p>
                  <h5 class="font-weight-bolder mb-0">
                    {{ metrics.clientsTotal }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 mt-2">
          <div class="card z-index-2">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <h6 class="">
                    Нові клієнти
                    <a
                      href="javascript:;"
                      class="mx-3"
                      v-if="company.crmMode === 'hotel'"
                      data-bs-toggle="tooltip"
                      data-bs-original-title="Кількість клієнтів які забронювали вперше за обраний період."
                    >
                      <i
                        class="material-icons text-secondary position-relative text-lg"
                        >info</i
                      >
                    </a>
                    <a
                      href="javascript:;"
                      class="mx-3"
                      v-if="company.crmMode !== 'hotel'"
                      data-bs-toggle="tooltip"
                      data-bs-original-title="Кількість клієнтів які записались вперше за обраний період."
                    >
                      <i
                        class="material-icons text-secondary position-relative text-lg"
                        >info</i
                      >
                    </a>
                  </h6>
                </div>
                <div class="col-3">
                  <h5>{{ metrics.clientsNew }}</h5>
                </div>
              </div>
              <hr class="dark horizontal" />
              <div class="d-flex">
                <i class="material-icons text-sm my-auto me-1">person</i>
                <router-link
                  :to="`/ecommerce/clients?customView=clientsNew&dateFrom=${selectedClientsPeriodFrom}&dateTo=${selectedClientsPeriodTo}&staffId=${
                    this.selectedStaffId || ''
                  }&positionId=${this.selectedPositionId || ''}`"
                >
                  <p class="mb-0 text-sm">Подивитись клієнтів</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-2">
          <div class="card z-index-2">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <h6 class="">
                    Повторні візити
                    <a
                      href="javascript:;"
                      class="mx-3"
                      v-if="company.crmMode !== 'hotel'"
                      data-bs-toggle="tooltip"
                      data-bs-original-title="Кількість клієнтів здійснивших другий і більше візит за обраний період."
                    >
                      <i
                        class="material-icons text-secondary position-relative text-lg"
                        >info</i
                      >
                    </a>
                    <a
                      href="javascript:;"
                      class="mx-3"
                      v-if="company.crmMode === 'hotel'"
                      data-bs-toggle="tooltip"
                      data-bs-original-title="Кількість клієнтів здійснивших друге і більше бронювань за обраний період."
                    >
                      <i
                        class="material-icons text-secondary position-relative text-lg"
                        >info</i
                      >
                    </a>
                  </h6>
                </div>
                <div class="col-3">
                  <h5>{{ metrics.clientsReturned }}</h5>
                </div>
              </div>
              <hr class="dark horizontal" />
              <div class="d-flex">
                <i class="material-icons text-sm my-auto me-1">person</i>
                <router-link
                  :to="`/ecommerce/clients?customView=clientsReturned&dateFrom=${selectedClientsPeriodFrom}&dateTo=${selectedClientsPeriodTo}&staffId=${
                    this.selectedStaffId || ''
                  }&positionId=${this.selectedPositionId || ''}`"
                >
                  <p class="mb-0 text-sm">Подивитись клієнтів</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-2" v-if="showDevFeatures">
          <div class="card z-index-2">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <h6 class="">
                    DEV Загублені
                    <a
                      href="javascript:;"
                      class="mx-3"
                      data-bs-toggle="tooltip"
                      data-bs-original-title="Показуємо скільки клієнтів здійснили останній візит в період равний по тривалості обраному, та який розпочався за {45 днів} до початку обраного періоду. Відсоток показує яку частину складають загублені клієнти від усіх клієнтів за обраний період."
                    >
                      <i
                        class="material-icons text-secondary position-relative text-lg"
                        >info</i
                      >
                    </a>
                  </h6>
                </div>
                <div class="col-3">
                  <h5>{{ metrics.clientsLost }}</h5>
                </div>
              </div>
              <hr class="dark horizontal" />
              <div class="d-flex">
                <i class="material-icons text-sm my-auto me-1">person</i>
                <router-link
                  :to="`/ecommerce/clients?customView=clientsLost&dateFrom=${selectedClientsPeriodFrom}&dateTo=${selectedClientsPeriodTo}&staffId=${
                    this.selectedStaffId || ''
                  }&positionId=${this.selectedPositionId || ''}`"
                >
                  <p class="mb-0 text-sm">Подивитись клієнтів</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5" v-if="showDevFeatures">
      <h5>
        Майстри
        <a
          href="javascript:;"
          class="mx-3"
          data-bs-toggle="tooltip"
          data-bs-original-title="Зеленим кольором - показники які збільшились в порівняння з попереднім періодом. Червоним - якщо показник знизився. В дужках відображено на скільки показник змінився у порівняння з попереднім періодом."
        >
          <i class="material-icons text-secondary position-relative text-lg"
            >info</i
          >
        </a>
        <div class="badge badge-info badge-sm">
          {{ computedSelectedDateRange }}
        </div>
      </h5>
    </div>
    <div class="row" v-if="showDevFeatures">
      <div class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-4">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <h6 class="">По завантаженості</h6>
            <p class="mb-0 text-sm">
              <a target="_blank">
                <img
                  class="gold-cup"
                  src="../../assets/img/gold-cup.png"
                  alt="gold-cup.png"
                />
              </a>
              Майстер 2
              <span class="text-sm font-weight-bolder">80%</span
              ><span class="text-success">
                (10%<i class="ni ni-bold-up text-sm ms-1 text-success"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              <a target="_blank">
                <img
                  class="silver-cup"
                  src="../../assets/img/silver-cup.png"
                  alt="silver-cup.png"
                />
              </a>
              Майстер 1
              <span class="text-sm font-weight-bolder">55%</span
              ><span class="text-success">
                (3%<i class="ni ni-bold-up text-sm ms-1 text-success"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              <a target="_blank">
                <img
                  class="bronze-cup"
                  src="../../assets/img/bronze-cup.png"
                  alt="bronze-cup.png"
                />
              </a>
              Майстер 3
              <span class="text-sm font-weight-bolder">49%</span
              ><span class="text-danger">
                (10%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              Майстер 6
              <span class="text-sm font-weight-bolder">43%</span
              ><span class="text-danger">
                (20%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              Майстер 5
              <span class="text-sm font-weight-bolder">42%</span
              ><span class="text-danger">
                (50%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              Майстер 4
              <span class="text-sm font-weight-bolder">49%</span
              ><span class="text-danger">
                (10%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-4">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <h6 class="">По грошам</h6>
            <p class="mb-0 text-sm">
              <a target="_blank">
                <img
                  class="gold-cup"
                  src="../../assets/img/gold-cup.png"
                  alt="gold-cup.png"
                />
              </a>
              Майстер 2
              <span class="text-sm font-weight-bolder">8356грн</span
              ><span class="text-success">
                (10%<i class="ni ni-bold-up text-sm ms-1 text-success"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              <a target="_blank">
                <img
                  class="silver-cup"
                  src="../../assets/img/silver-cup.png"
                  alt="silver-cup.png"
                />
              </a>
              Майстер 1
              <span class="text-sm font-weight-bolder">7356грн</span
              ><span class="text-danger">
                (10%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              <a target="_blank">
                <img
                  class="bronze-cup"
                  src="../../assets/img/bronze-cup.png"
                  alt="bronze-cup.png"
                />
              </a>
              Майстер 3
              <span class="text-sm font-weight-bolder">6356грн</span
              ><span class="text-danger">
                (1%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              Майстер 6
              <span class="text-sm font-weight-bolder">5356грн</span
              ><span class="text-danger">
                (10%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              Майстер 5
              <span class="text-sm font-weight-bolder">4356грн</span
              ><span class="text-danger">
                (10%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              Майстер 4
              <span class="text-sm font-weight-bolder">3356грн</span
              ><span class="text-danger">
                (10%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-4">
        <div class="card mb-2">
          <div class="card-header p-3 pt-2 bg-transparent">
            <h6 class="">По робочому часу</h6>
            <p class="mb-0 text-sm">
              <a target="_blank">
                <img
                  class="gold-cup"
                  src="../../assets/img/gold-cup.png"
                  alt="gold-cup.png"
                />
              </a>
              Майстер 2
              <span class="text-sm font-weight-bolder">140 год</span
              ><span class="text-danger">
                (10%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              <a target="_blank">
                <img
                  class="silver-cup"
                  src="../../assets/img/silver-cup.png"
                  alt="silver-cup.png"
                />
              </a>
              Майстер 1
              <span class="text-sm font-weight-bolder">115 год 30 хв</span
              ><span class="text-danger">
                (10%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              <a target="_blank">
                <img
                  class="bronze-cup"
                  src="../../assets/img/bronze-cup.png"
                  alt="bronze-cup.png"
                />
              </a>
              Майстер 3
              <span class="text-sm font-weight-bolder">110 год 30 хв</span
              ><span class="text-danger">
                (10%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              Майстер 6
              <span class="text-sm font-weight-bolder">105 год 30 хв</span
              ><span class="text-danger">
                (10%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              Майстер 5
              <span class="text-sm font-weight-bolder">70 год</span
              ><span class="text-danger">
                (10%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
            <p class="mb-0 text-sm">
              Майстер 4
              <span class="text-sm font-weight-bolder">20 год</span
              ><span class="text-danger">
                (10%<i class="ni ni-bold-down text-sm ms-1 text-danger"></i
                >)</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.gold-cup {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-image: url("../../assets/img/gold-cup.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.silver-cup {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-image: url("../../assets/img/silver-cup.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.bronze-cup {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-image: url("../../assets/img/bronze-cup.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.wrapper-page-analytics >>> .flatpickr-input {
  color: #095bc6;
  background-color: #aecef7;
  border: 0;
  border-radius: 0.375rem;
  text-align: center;
  padding: 10px;
  font-weight: 700;
}

.bmn-staff-removed {
  text-decoration: line-through;
  opacity: 0.7;
}

.parent-container-no-data {
  height: 400px;
}

.vertical-center {
  font-size: x-large;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>

<script>
import { DateTime } from "luxon";
import * as _ from "lodash";
import Spinner from "@/views/shared/Spinner.vue";

import setTooltip from "@/assets/js/tooltip.js";
import api from "@/services/api";
import constants from "@/constants";

import flatPickr from "vue-flatpickr-component";
import { Ukrainian } from "flatpickr/dist/l10n/uk";

import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

const paletteColors = [
  "#1A73E8",
  "#4CAF50",
  "#D6EAFC",
  "#FFFFDF",
  "#D3C4E3",
  "#B8DEDB",
  "#D3C4E3",
  "GoldenRod",
  "Lavender",
  "LavenderBlush",
  "LightBlue",
  "LightGoldenRodYellow",
  "NavajoWhite",
  "PaleGreen",
  "PaleTurquoise",
  "PapayaWhip",
  "PeachPuff",
  "Pink",
  "Plum",
  "RosyBrown",
  "Salmon",
  "SkyBlue",
  // "#F47A1F",
  // "#FDBB2F",
  // "#377B2B",
  // "#7AC142",
  // "#007CC3",
  // "#00529B",
  // "#6050DC",
  // "#D52DB7",
  // "#FF2E7E",
  // "#FF6B45",
  // "#FFAB05",
  // "#E6F69D",
  // "#AADEA7",
  // "#64C2A6",
  // "#2D87BB",
];

const getPeriod = (name) => {
  const period = {
    dateFrom: null,
    dateTo: null,
  };

  switch (name) {
    case "allTime":
      period.dateFrom = null;
      period.dateTo = null;
      break;

    case "currentWeek":
      period.dateFrom = DateTime.now().startOf("week").toISODate();
      period.dateTo = DateTime.now().endOf("week").toISODate();
      break;

    case "previousWeek":
      period.dateFrom = DateTime.now()
        .minus({ week: 1 })
        .startOf("week")
        .toISODate();
      period.dateTo = DateTime.now()
        .minus({ week: 1 })
        .endOf("week")
        .toISODate();
      break;

    case "currentMonth":
      period.dateFrom = DateTime.now().startOf("month").toISODate();
      period.dateTo = DateTime.now().endOf("month").toISODate();
      break;

    case "nextMonth":
      period.dateFrom = DateTime.now()
        .plus({ month: 1 })
        .startOf("month")
        .toISODate();
      period.dateTo = DateTime.now()
        .plus({ month: 1 })
        .endOf("month")
        .toISODate();
      break;

    case "previousMonth":
      period.dateFrom = DateTime.now()
        .minus({ month: 1 })
        .startOf("month")
        .toISODate();
      period.dateTo = DateTime.now()
        .minus({ month: 1 })
        .endOf("month")
        .toISODate();
      break;

    case "currentYear":
      period.dateFrom = DateTime.now().startOf("year").toISODate();
      period.dateTo = DateTime.now().endOf("year").toISODate();
      break;

    case "previousYear":
      period.dateFrom = DateTime.now()
        .minus({ year: 1 })
        .startOf("year")
        .toISODate();
      period.dateTo = DateTime.now()
        .minus({ year: 1 })
        .endOf("year")
        .toISODate();
      break;

    case "yesterday":
      period.dateFrom = DateTime.now()
        .minus({ days: 1 })
        .startOf("day")
        .toISODate();
      period.dateTo = period.dateFrom;
      break;

    case "monthToDate": // MTD
      period.dateFrom = DateTime.now().startOf("month").toISODate();
      period.dateTo = DateTime.now().toISODate();
      break;

    case "yearToDate": // YTD
      period.dateFrom = DateTime.now().startOf("year").toISODate();
      period.dateTo = DateTime.now().toISODate();
      break;

    case "today":
    default:
      period.dateFrom = DateTime.now().toISODate();
      period.dateTo = period.dateFrom;
      break;
  }

  return period;
};

export default {
  name: "ecommerce-analytics",

  components: {
    flatPickr,
    Pie,
    Spinner,
  },

  data() {
    return {
      dataLoaded: false,
      showDevFeatures: false,

      company: {
        crmMode: "",
      },

      selectedDateRange: "currentMonth",
      listDateRanges: [
        {
          _id: "previousMonth",
          name: "Попередній місяць",
        },
        {
          _id: "currentMonth",
          name: "Поточний місяць",
        },
        {
          _id: "nextMonth",
          name: "Наступний місяць",
        },
        {
          _id: "currentWeek",
          name: "Поточний тиждень",
        },
        {
          _id: "previousWeek",
          name: "Попередній тиждень",
        },
        {
          _id: "currentYear",
          name: "Поточний рік",
        },
        {
          _id: "previousYear",
          name: "Попередній рік",
        },
        {
          _id: "today",
          name: "Сьогодні",
        },
        {
          _id: "yesterday",
          name: "Вчора",
        },
        {
          _id: "monthToDate",
          name: "Поточний місяць (до сьогодні)",
        },
        {
          _id: "yearToDate",
          name: "Поточний рік (до сьогодні)",
        },
        {
          _id: "custom",
          name: "Довільні дати",
        },
      ],
      customSelectedDateRange: null,
      calendarConfig: {
        mode: "range",
        dateFormat: "d-m-Y",
        locale: Ukrainian,
        inline: true,
        disableMobile: true,
        onChange: async (selectedDates) => {
          if (_.isArray(selectedDates) && selectedDates.length === 2) {
            this.selectedDateFrom = DateTime.fromJSDate(
              selectedDates[0]
            ).toISODate();
            this.selectedDateTo = DateTime.fromJSDate(
              selectedDates[1]
            ).toISODate();
            await this.fetchData();
          }
        },
      },

      selectedClientsPeriodFrom: null,
      selectedClientsPeriodTo: null,
      clientsPeriod: null,
      calendarConfigClients: {
        defaultDate: Date.now(),
        plugins: [
          new MonthSelectPlugin({
            shorthand: false, //defaults to false
            dateFormat: "🗓 F Y", //defaults to "F Y"
            altFormat: "🗓 F Y", //defaults to "F Y"
            theme: "light", // defaults to "light"
          }),
        ],
        locale: Ukrainian,
        inline: false,
        disableMobile: true,
        onChange: async (selectedDates) => {
          if (!_.isArray(selectedDates) || selectedDates.length !== 1) {
            selectedDates = [new Date()];
          }

          this.selectedClientsPeriodFrom = DateTime.fromJSDate(selectedDates[0])
            .startOf("month")
            .toISODate();
          this.selectedClientsPeriodTo = DateTime.fromJSDate(selectedDates[0])
            .endOf("month")
            .toISODate();

          const todayDate = DateTime.now().toISODate();
          if (
            DateTime.fromISO(todayDate).startOf("month").toISODate() ===
            this.selectedClientsPeriodFrom
          ) {
            // exception: current month -> to current date
            this.selectedClientsPeriodTo = todayDate;
          }

          await this.fetchDataClients();
        },
        maxDate: Date.now(),
      },

      selectedPositionId: null,
      listPositions: [
        {
          _id: null,
          name: "Всі посади",
          positionId: null,
        },
      ],

      selectedStaffId: null,
      listStaff: [
        {
          _id: null,
          name: "Всі",
          positionId: null,
        },
      ],

      selectedDateFrom: null,
      selectedDateTo: null,

      metrics: {
        serviceOrdersIncomeSum: null,
        serviceOrdersIncomeMean: null,
        serviceOrdersCountersTotal: null,
        serviceOrdersCountersNew: null,
        serviceOrdersCountersDone: null,
        serviceOrdersCountersCancelled: null,
        serviceOrdersCountersByChannel: {
          labels: [],
          datasets: [],
        },
        serviceOrdersCountersByStaff: {
          labels: [],
          datasets: [],
        },

        staffLoadOverall: null,

        clientsNew: null,
        clientsReturned: null,
        clientsLost: null,
        clientsTotal: null,
      },

      charts: {
        bookingsByChannel: {
          data: {
            labels: ["Адмін панель", "Онлайн запис"],
            datasets: [
              {
                backgroundColor: paletteColors,
                data: [],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
        },
        bookingsByStaff: {
          data: {
            labels: [],
            datasets: [
              {
                backgroundColor: paletteColors,
                data: [],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
        },
      },
    };
  },

  async created() {
    this.company = await api.company.show();

    this.listStaff[0].name = this.getLabelStaff();

    // TODO: set includeRemoved to 1 to include removed staffs
    const staff = await api.staff.list({ includeRemoved: 0 });
    const positions = await api.positions.list();

    this.listStaff = [...this.listStaff, ...staff];
    this.listPositions = [...this.listPositions, ...positions];

    const defaultPeriod = getPeriod("currentMonth");
    this.selectedDateFrom = defaultPeriod.dateFrom;
    this.selectedDateTo = defaultPeriod.dateTo;

    await this.fetchData();
  },

  async mounted() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
    setTimeout(setTooltip, 200);
    await this.calendarConfigClients.onChange();
    this.dataLoaded = true;
  },

  methods: {
    _isHotel() {
      return this.company.crmMode === "hotel";
    },

    getLabelStaff() {
      const tenantName = this.$store.state.tenantName;
      switch (true) {
        case tenantName === "bookmenow":
        case tenantName === "ecopark-medvino":
        case tenantName === "zdorovenki-buly":
        case tenantName === "nature-oasis":
        case tenantName === "greenfire-spa":
        case tenantName === "travelkayak":
        case tenantName === "hatchyna-spa":
        case tenantName === "turhouse-spa":
        case tenantName === "example-spa":
        case tenantName === "driada":
        case tenantName === "family-evans-spa":
        case tenantName === "apt-oldmill-spa":
          return "Всі ресурси";
        // TODO: we have _isHotel() method, but it's not used here
        case constants.hotelModeList.includes(tenantName):
          return "Всі номери";
        default:
          return "Всі майстри";
      }
    },

    getLabelPieByStaff() {
      const tenantName = this.$store.state.tenantName;
      switch (true) {
        case tenantName === "bookmenow":
        case tenantName === "travelkayak":
        case tenantName === "zdorovenki-buly":
        case tenantName === "nature-oasis":
        case tenantName === "greenfire-spa":
        case tenantName === "ecopark-medvino":
        case tenantName === "hatchyna-spa":
        case tenantName === "turhouse-spa":
        case tenantName === "example-spa":
        case tenantName === "driada":
        case tenantName === "family-evans-spa":
          return "Записи по ресурсам";
        case constants.hotelModeList.includes(tenantName):
          return "Броні по номерам";
        default:
          return "Записи по майстрам";
      }
    },

    async fetchData() {
      const payload = {
        scope: "general",
        staffId: this.selectedStaffId,
        positionId: this.selectedPositionId,
      };

      if (this.selectedDateFrom) {
        payload.dateFrom = this.selectedDateFrom;
      }

      if (this.selectedDateTo) {
        payload.dateTo = this.selectedDateTo;
      }

      const metrics = await api.analytics.list(payload);

      this.metrics = {
        ...this.metrics,
        ...metrics,
      };

      this.charts.bookingsByChannel.data.datasets[0].data =
        metrics.serviceOrdersCountersByChannel.datasets;
      if (
        !this.charts.bookingsByChannel.data.datasets[0].data[0] &&
        !this.charts.bookingsByChannel.data.datasets[0].data[1]
      ) {
        this.charts.bookingsByChannel.data.datasets[0].data = [];
      }

      this.charts.bookingsByStaff.data.labels =
        metrics.serviceOrdersCountersByStaff.labels;
      this.charts.bookingsByStaff.data.datasets[0].data =
        metrics.serviceOrdersCountersByStaff.datasets;
    },

    async fetchDataClients() {
      const payload = {
        scope: "clients",
        dateFrom: this.selectedClientsPeriodFrom,
        dateTo: this.selectedClientsPeriodTo,
        staffId: this.selectedStaffId,
        positionId: this.selectedPositionId,
      };

      const metrics = await api.analytics.list(payload);
      this.metrics = {
        ...this.metrics,
        ...metrics,
      };
    },

    async filterByDateRange(dateRange) {
      this.selectedDateRange = dateRange;

      const rangeValue = getPeriod(dateRange);
      this.selectedDateFrom = rangeValue.dateFrom;
      this.selectedDateTo = rangeValue.dateTo;

      if (dateRange !== "custom") {
        this.$refs.datePickerWrap.fp.clear();
        await this.fetchData();
      }
    },
    displaySelectedDateRange() {
      const _dateRange = this.listDateRanges.find((element) => {
        return element._id === this.selectedDateRange;
      });
      return _dateRange.name;
    },
    async filterByPositionId(positionId) {
      this.selectedPositionId = positionId;
      await this.fetchData();
      await this.fetchDataClients();
    },
    displaySelectedPosition() {
      const _position = this.listPositions.find((element) => {
        return element._id === this.selectedPositionId;
      });
      return _position.name;
    },
    async filterByStaffId(staffId) {
      this.selectedStaffId = staffId;
      await this.fetchData();
      await this.fetchDataClients();
    },
    displaySelectedStaff() {
      const _staff = this.listStaff.find((element) => {
        return element._id === this.selectedStaffId;
      });

      const suffix = _staff._id && !_staff.isActive ? "❌" : "";

      if (_staff.positionId) {
        return `${_staff.name} (${_staff.positionId.name}) ${suffix}`;
      }
      return `${_staff.name} ${suffix}`;
    },
  },

  computed: {
    computedSelectedDateRange() {
      if (this.selectedDateRange === "allTime") {
        return "За весь час";
      }

      let from = "(Дата з)";
      let to = "(Дата до)";
      if (this.selectedDateFrom) {
        from = DateTime.fromISO(this.selectedDateFrom)
          .setLocale("uk-UA")
          .toFormat("dd.MM.yyyy");
      }
      if (this.selectedDateTo) {
        to = DateTime.fromISO(this.selectedDateTo)
          .setLocale("uk-UA")
          .toFormat("dd.MM.yyyy");
      }
      return `${from} - ${to}`;
    },
  },
};
</script>
