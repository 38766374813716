<template>
  <div class="container-fluid">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div v-if="dataLoaded" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              На цій сторінці зібрані всі відгуки які залишили вашими гостями.
              Ви можете зняти з публікації отриманий відгук за бажанням, щоб він
              не відображався іншим гостям.
            </div>
            <hr />
            <div class="row">
              Середня оцінка: {{ avgRating }} <br />
              Середня оцінка (лише опубліковані): {{ avgPublishedRating }}
              <!-- <div class="col-lg-4">Середня оцінка по неопублікованим: {{ avgUnpublishedRating }}</div> -->
            </div>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-12">
            <div class="card">
              <div v-if="tableData.rows.length" class="table-responsive">
                <vue-good-table
                  v-if="dataLoaded"
                  :columns="columns"
                  :rows="tableData.rows"
                  :sort-options="{ enabled: false }"
                >
                  <template #table-row="props">
                    <div
                      v-if="props.column.field === 'client'"
                      class="d-flex px-2 py-1"
                    >
                      <div class="d-flex flex-column">
                        <p class="text-xs font-weight-normal mb-0">
                          {{ props.row.clientId.firstName }}
                          {{ props.row.clientId.lastName }}
                        </p>
                      </div>
                    </div>
                    <div v-if="props.column.field === 'number'">
                      <div class="d-flex flex-column justify-content-center">
                        <p class="text-xs font-weight-normal mt-1">
                          <router-link
                            :to="`/ecommerce/service-orders/${props.row.serviceOrderId?._id}`"
                          >
                            {{
                              props.row.serviceOrderId?.sequenceId ||
                              props.row.serviceOrderId?._id
                            }}
                          </router-link>
                        </p>
                      </div>
                    </div>
                    <div v-if="props.column.field === 'status'">
                      <span
                        v-if="props.row.published"
                        class="badge badge-dot me-4"
                      >
                        <i class="bg-success"></i>
                        <span class="text-dark text-xs">Опубліковано</span>
                      </span>
                      <span v-else class="badge badge-dot me-4">
                        <i class="bg-warning"></i>
                        <span class="text-dark text-xs">Неопублікований</span>
                      </span>
                    </div>
                    <div v-if="props.column.field === 'avgRating'">
                      <p class="text-xs font-weight-normal mb-0">
                        {{ props.row.avgRating }}
                      </p>
                    </div>
                    <div v-if="props.column.field === 'comment'">
                      <p class="text-xs font-weight-normal mb-0">
                        {{ props.row.comment }}
                      </p>
                    </div>
                    <div v-if="props.column.field === 'createdAt'">
                      <p class="text-xs font-weight-normal mb-0">
                        {{ timestampToString(props.row.createdAt) }}
                      </p>
                    </div>
                    <div v-if="props.column.field === 'actions'">
                      <p class="text-xs font-weight-normal mb-0">
                        <router-link :to="`/edit-review/${props.row._id}`">
                          <i class="material-icons text-lg me-2 cursor-pointer"
                            >edit</i
                          >
                        </router-link>
                      </p>
                    </div>
                  </template>
                </vue-good-table>
              </div>
              <div v-else class="row mt-3">
                <div class="col text-center">
                  Відкуків поки що немає<br />
                  <img
                    src="../../../assets/img/gif/no-feedbacks.gif"
                    alt="No Feedbacks"
                    width="300"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import { DateTime } from "luxon";
import { useTable } from "@/composables/good-table";

import Spinner from "@/views/shared/Spinner.vue";

export default {
  setup() {
    const { loadItems, tableData, isLoading } = useTable(api.feedbacks.list);

    return {
      loadItems,
      isLoading,
      tableData,
    };
  },
  components: { Spinner },
  name: "review-overview",
  data() {
    return {
      dataLoaded: false,
      columns: [
        {
          label: "Клієнт",
          field: "client",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal",
        },
        {
          label: "Номер броні",
          field: "number",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Статус відгука",
          field: "status",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Середня оцінка",
          field: "avgRating",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Текст відгуку",
          field: "comment",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal",
        },
        {
          label: "Дата відгука",
          field: "createdAt",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Дія",
          field: "actions",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
      ],
    };
  },
  computed: {
    avgRating: function () {
      return this.calcAvgRating(this.tableData.rows);
    },
    avgPublishedRating: function () {
      return this.calcAvgRating(
        this.tableData.rows.filter((row) => row.published)
      );
    },
    avgUnpublishedRating: function () {
      return this.calcAvgRating(
        this.tableData.rows.filter((row) => !row.published)
      );
    },
  },
  async mounted() {
    setTimeout(async () => {
      await this.loadItems();
      this.dataLoaded = true;
    }, 500);
  },
  methods: {
    calcAvgRating: function (values) {
      return values.length
        ? (
            values
              .map((attr) => attr.avgRating)
              .reduce((res, a) => res + a, 0) / values.length
          ).toFixed(1)
        : 0;
    },
    timestampToString: function (input) {
      if (!input) {
        return "-";
      }

      const datetime = DateTime.fromJSDate(DateTime.fromISO(input).toJSDate());
      return datetime
        .setLocale("uk-UA")
        .toLocaleString({ month: "short", day: "numeric", year: "numeric" });
    },
  },
};
</script>
