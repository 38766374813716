<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useFetch } from "@/composables/common/api/useFetch";
import api from "@/services/api";
import { Ukrainian } from "flatpickr/dist/l10n/uk";
import flatPickr from "vue-flatpickr-component";
import { DateTime } from "luxon";
import Bg from "@/assets/img/background.jpeg";
import html2canvas from "html2canvas";
import ButtonApp from "@/components/common/other/ButtonApp.vue";
import Emoji01 from "@/assets/img/emoji/Blue-Heart.svg";
import Emoji02 from "@/assets/img/emoji/Orange-Heart.svg";
import Emoji03 from "@/assets/img/emoji/Purple-Heart.svg";
import Emoji04 from "@/assets/img/emoji/White-Heart.svg";
import Emoji05 from "@/assets/img/emoji/Yellow-Heart.svg";
import Emoji06 from "@/assets/img/emoji/Black-Heart.svg";
import Emoji07 from "@/assets/img/emoji/Red-Heart.svg";
import Emoji08 from "@/assets/img/emoji/Brown-Heart.svg";
import Emoji09 from "@/assets/img/emoji/Green-Heart.svg";
import Emoji10 from "@/assets/img/emoji/Evergreen-Tree.svg";
import Emoji11 from "@/assets/img/emoji/Mountain.svg";
import Emoji12 from "@/assets/img/emoji/Check-Mark-Button.svg";
import Emoji13 from "@/assets/img/emoji/Cross-Mark.svg";
import Emoji14 from "@/assets/img/emoji/prozori-cross.svg";
import Emoji15 from "@/assets/img/emoji/Fire.svg";
import Emoji16 from "@/assets/img/emoji/No-Entry.svg";
import Emoji17 from "@/assets/img/emoji/Round-Pushpin.svg";
import Emoji18 from "@/assets/img/emoji/Home.svg";
import Emoji19 from "@/assets/img/emoji/wave.svg";
import LogoIcon from "@/components/icons/LogoIcon.vue";
import SwitcherApp from "@/components/common/inputs/SwitcherApp.vue";
import * as _ from "lodash";
import SelectApp from "@/components/common/SelectApp.vue";

const {
  data: items,
  isLoading,
  fetchData: getItems,
} = useFetch(api.staff.list);

const {
  data: availableDates,
  isLoading: isLoadingAvailableDates,
  fetchData: getAvailableDates,
} = useFetch(api.serviceOrders.getAvailableDates);

const DEFAULT_ALL = {
  rank: 0,
  categoryName: "Всі номери",
  categoryId: "all",
  rooms: [{ label: "Всі номери", key: "all" }],
};

const optionsSelect = ref([DEFAULT_ALL]);
const selectedItems = ref([{ label: "Всі номери", key: "all" }]);

const disabledDates = ref([]);
const disableDatesForCalendar = computed(() => {
  return filteredDates(disabledDates.value);
});

watch(disableDatesForCalendar, (val) => {
  configCalendar.value.disable = val;
});

const itemsIds = computed(() => {
  const selectedAll =
    selectedItems.value.findIndex((item) => item.key === "all") >= 0;
  if (selectedAll) {
    return optionsSelect.value.slice(1).reduce((acc, current) => {
      let result = current.rooms.map((item) => item.key);
      return acc.concat(result);
    }, []);
  }
  return selectedItems.value.map((item) => item.key);
});

onMounted(async () => {
  await getItems([{ isActive: 1 }]);

  let resultArr = [];
  items.value.forEach((item) => {
    let categoryIndex = resultArr.findIndex(
      (category) => category.categoryId === item.positionId._id
    );

    const roomItem = {
      label: `${item.name}`,
      key: item._id,
    };

    if (categoryIndex >= 0) {
      resultArr[categoryIndex].rooms.push(roomItem);
    } else {
      const categoryName = item.positionId.name;
      const categoryId = item.positionId._id;
      const rank = item.positionId.rank;

      const itemWithCategory = {
        categoryName,
        categoryId,
        rank,
        rooms: [roomItem],
      };
      resultArr.push(itemWithCategory);
    }
  });

  resultArr = _.sortBy(resultArr, "rank");
  optionsSelect.value = optionsSelect.value.concat(resultArr);

  await getAllDisableDates(itemsIds.value);
});

watch(selectedItems, (item) => {
  getAllDisableDates(itemsIds.value);
});

const date = ref(DateTime.now().toISODate());
const configCalendar = ref({
  mode: "single",
  dateFormat: "Y-m-d",
  locale: Ukrainian,
  inline: true,
});

const colorPastDates = ref(false);
watch(
  colorPastDates,
  (val) => {
    if (val) {
      configCalendar.value.minDate = DateTime.now().toISODate();
    } else {
      configCalendar.value.minDate = "";
    }
  },
  { immediate: true }
);

const captureRef = ref(null);

function saveCapture() {
  html2canvas(captureRef.value, {
    backgroundColor: null,
    scale: 3,
  }).then(function (canvas) {
    let dataURL = canvas.toDataURL("image/png");
    // captureRef.value.appendChild(canvas);
    downloadImage(dataURL, "calendar.png");
  });
}
function downloadImage(data, filename = "untitled.png") {
  let a = document.createElement("a");
  a.href = data;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
}

const images = ref([
  Emoji01,
  Emoji02,
  Emoji03,
  Emoji04,
  Emoji05,
  Emoji06,
  Emoji07,
  Emoji08,
  Emoji09,
  Emoji10,
  Emoji11,
  Emoji12,
  Emoji13,
  Emoji14,
  Emoji15,
  Emoji16,
  Emoji17,
  Emoji18,
  Emoji19,
]);
const selectedImage = ref(images.value[0]);

watch(
  [selectedImage, captureRef],
  ([img, ref]) => {
    if (img && ref) {
      captureRef.value?.style.setProperty("--bg", `url(${img})`);
    }
  },
  { immediate: true }
);

const backgroundOptions = ref([
  { label: "Напівпрозорий", value: "half-transparent" },
  { label: "Прозорий", value: "transparent" },
  { label: "Білий", value: "white" },
]);

const selectedBackground = ref(backgroundOptions.value[0]);

function filteredDates(arr) {
  let result = [];
  if (!arr.length) {
    return result;
  }

  arr[0].disable.forEach((item) => {
    const isInAllRooms = arr.every((room) => {
      return room.disable.indexOf(item) >= 0;
    });

    if (isInAllRooms) {
      result.push(item);
    }
  });

  return result;
}
async function getAllDisableDates(arrId) {
  const promises = arrId.map(async (id) => {
    return new Promise((resolve) => {
      getAvailableDates([id]).then((res) => {
        resolve({ id, disable: res.disable });
      });
    });
  });

  Promise.all(promises).then((values) => {
    disabledDates.value = values;
  });
}

function onUpdate(items) {
  const indexAll = selectedItems.value.findIndex((item) => item.key === "all");
  if (
    selectedItems.value.length > 1 &&
    indexAll === selectedItems.value.length - 1
  ) {
    selectedItems.value = [selectedItems.value[indexAll]];
    return;
  }
  if (indexAll >= 0 && indexAll !== selectedItems.value.length - 1) {
    selectedItems.value.splice(indexAll, 1);
  }
}
</script>
<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-lg-12 mt-lg-0">
        <!-- Intro Info -->
        <div class="card">
          <div class="card-body">
            <h4>IG stories maker</h4>
            <p class="text-sm">
              Завдяки IG Stories maker ви можете дуже швидко створити картинку
              календаря вільних і зайнятих дат, щоб завантажити її в Instagram.
              Оскільки bookmenow вже знає всі вільні й зайняті дати - єдине що
              вам залишилось, це обрати стиль календаря.
              <br /><br />
              1. Оберіть стилістику календаря<br />
              2. Збережіть календар картинкою<br />
              3. Використайте картинку календаря в Stories в Instagram
            </p>
          </div>
        </div>
        <!-- Intro finish -->
        <div class="card mt-2">
          <div class="card-body">
            <h4>Налаштуйте вигляд календаря</h4>
            <div
              class="d-flex gap-2 gap-lg-4 flex-column flex-wrap flex-sm-row align-items-sm-start"
            >
              <div class="input-group input-group-static select">
                <label>Оберіть номер</label>
                <SelectApp
                  v-model="selectedItems"
                  :settings="{
                    allowEmpty: false,
                    groupSelect: true,
                    loading: isLoading,
                    options: optionsSelect,
                    groupLabel: 'categoryName',
                    groupValues: 'rooms',
                    trackBy: 'label',
                    taggable: true,
                    multiple: true,
                    label: 'label',
                  }"
                  @update:modelValue="onUpdate"
                />
              </div>
              <div class="input-group input-group-static select">
                <label>Оберіть колір фону</label>
                <SelectApp
                  v-model="selectedBackground"
                  :settings="{
                    allowEmpty: false,
                    loading: isLoading,
                    options: backgroundOptions,
                    trackBy: 'label',
                    label: 'label',
                  }"
                />
              </div>
              <div class="">
                <div class="d-flex align-items-center mt-4 gap-1 pb-2">
                  <SwitcherApp v-model="colorPastDates" />
                  <span>Зафарбувати минулі дати</span>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <label>Оберіть Emoji яким ми замалюємо зайняті дати</label><br />
              <div class="emoji__items">
                <div
                  v-for="(img, index) of images"
                  :key="index"
                  :class="{ selected: img === selectedImage }"
                  class="emoji__item"
                  @click="selectedImage = img"
                >
                  <img :src="img" alt="" />
                </div>
              </div>
            </div>
            <!--            <flat-pickr v-model="date" />-->
            <hr />
            <div class="row mt-2">
              <h4>Попередній перегляд</h4>
              <div class="row infoBlock">
                <div class="d-flex align-items-center gap-2">
                  <span class="material-symbols-outlined"> info </span>
                  <label>
                    Щоб змінити місяць або рік на календарі - натисніть на назву
                    місяця або року. <br />
                    Фонове зображення з будиночком, який ви бачите нижче,
                    розміщено для прикладу як може виглядати календар на якомусь
                    фоні. Цей фон при завантаженні збережено не буде,
                    зберігається лише календар.
                  </label>
                </div>
              </div>
              <div class="wrap-border">
                <div class="wrap-image">
                  <img :src="Bg" alt="" />
                </div>
                <div
                  ref="captureRef"
                  :class="{
                    default: selectedBackground.value === 'white',
                    'half-transparent':
                      selectedBackground.value === 'half-transparent',
                    transparent: selectedBackground.value === 'transparent',
                  }"
                  class="wrapper-capture"
                >
                  <flat-pickr v-model="date" :config="configCalendar" />
                  <div class="description">
                    <div class="description__icon"></div>
                    <div class="description__text">- заброньовані дати</div>
                  </div>
                  <img
                    class="logo"
                    src="../../assets/img/bmn-logo-horisontal-light.png"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg saveInstagramBtn">
                <ButtonApp
                  :class="[
                    'btn-icon',
                    'bg-gradient-primary',
                    'saveInstagramBtn',
                  ]"
                  @click="saveCapture"
                >
                  Зберегти зображення
                </ButtonApp>
              </div>
              <div class="col"></div>
              <div class="col"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.material-symbols-outlined {
  color: #3f67c7; /* Замените 'red' на желаемый цвет */
}
.infoBlock {
  background-color: #eef7ff;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 8px 4px 0px 4px;
}
.flatpickr-input {
  display: none;
}
.wrapper-capture {
  overflow: hidden;
  box-shadow: none;
  padding: 10px;
  border-radius: 10px;
  &.default {
    background: #fff;
  }
  &.transparent {
    background: transparent;
    :deep(.flatpickr-calendar.inline) {
      background: transparent !important;
    }
  }
  &.half-transparent {
    background: rgba(255, 255, 255, 0.5);
    :deep(.flatpickr-calendar.inline) {
      background: transparent !important;
    }
  }
}
.wrap-border {
  padding: 110px 70px;
  max-width: max-content;
  position: relative;
  @media (max-width: 767px) {
    padding: 80px 10px;
  }
}
.wrap-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
:deep(.flatpickr-prev-month),
:deep(.flatpickr-next-month) {
  display: none;
}
:deep(.flatpickr-current-month) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
:deep(.flatpickr-current-month .flatpickr-monthDropdown-months) {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: auto;
  text-align: center;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: 23px;
}
:deep(.flatpickr-current-month .flatpickr-monthDropdown-months:focus) {
  background: transparent;
}

:deep(.cur-month) {
  margin-left: 0 !important;
}

:deep(.flatpickr-calendar) {
  box-shadow: none;
}
:deep(.flatpickr-day.prevMonthDay) {
  visibility: hidden;
  opacity: 0;
}
:deep(.flatpickr-day.nextMonthDay) {
  visibility: hidden;
  opacity: 0;
}

:deep(.flatpickr-calendar .flatpickr-day.today:not(.selected)) {
  color: #344767;
  background-color: transparent !important;
}
:deep(.flatpickr-calendar .flatpickr-day.selected) {
  color: #344767;
  background-color: transparent !important;
}
:deep(.flatpickr-day.today.selected) {
  color: #344767 !important;
  background-color: transparent !important;
}

:deep(.flatpickr-day.flatpickr-disabled),
:deep(.flatpickr-day.today:not(.selected).flatpickr-disabled) {
  background-color: transparent !important;
  background: var(--bg) center / 24px 24px no-repeat !important;
  color: transparent !important;
}
.wrapper-capture {
  position: relative;
  z-index: 2;
}
.emoji__items {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.emoji__item {
  width: 20px;
  height: 20px;
  transition: all 0.2s ease;
  border-radius: 50%;
  padding: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.selected {
    box-shadow: 0px 0px 0px 2px #fff, 0px 0px 0px 4px #4caf50;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.description {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 12px;
  border-top: 1px solid rgba(#222, 0.3);
  margin-top: 12px;
  &__icon {
    width: 24px;
    height: 24px;
    background: var(--bg) center / 24px 24px no-repeat;
  }

  &__text {
    font-size: 16px;
    color: #222;
  }
}
.logo {
  display: block;
  max-width: 130px;
  margin: 0px auto 0;
}
.svg {
  width: 150px !important;
}
:deep(.dayContainer),
:deep(.flatpickr-days),
:deep(.flatpickr-calendar) {
  @media (max-width: 576px) {
    width: 100%;
    min-width: auto;
    max-width: 307px;
  }
}
.select {
  max-width: 300px;
  @media (max-width: 567px) {
    max-width: 100%;
  }
}
:deep(.flatpickr-calendar.arrowTop) {
  &::before,
  &::after {
    display: none;
  }
}
</style>
