<template>
  <div v-show="!dataLoaded" class="container-fluid">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div v-show="dataLoaded" class="container-fluid">
    <div class="row">
      <div class="col-8"></div>
      <div class="col-lg-4 text-end">
        <span class="text-bold"> Баланс: </span
        ><span class=""> {{ company.smsBalance }} СМС </span>
      </div>
    </div>
    <div class="row">
      <div id="comments" class="card">
        <div class="card-body pt-0 mt-2">
          <div class="row infoBlock">
            <div class="d-flex align-items-center gap-2">
              <span class="material-symbols-outlined"> info </span>
              <label class="text-xs">
                Перед відправкою розсилки, будь ласка, звʼяжіться з нашою
                Службою підтримки, щоб переконатись що у нас достатньо балансу
                СМС для здійснення розсилки
              </label>
            </div>
          </div>
          <div class="card-body p-1">
            <div class="row">
              <div class="col-lg-4">
                <h5>Текст SMS</h5>
                <div class="input-group input-group-outline">
                  <textarea
                    v-model="company.instantMessageText"
                    class="form-control"
                    placeholder="Введіть текст"
                    rows="2"
                    type="text"
                    @input="autoResizeTextarea"
                    ref="descriptionRef1"
                    style="height: 75px"
                  />
                </div>
                <div>
                  <p class="text-xs">{{ charCount }} / 661 символів</p>
                </div>
              </div>
              <div class="col-lg-4">
                <h5>Оберіть з бази клієнтів</h5>
                <SelectApp
                  v-model="selectedClients"
                  :settings="{
                    loading: isLoadingClients,
                    options: clientOptions,
                    placeholder: 'Оберіть клієнтів',
                    trackBy: 'code',
                    closeOnSelect: false,
                    taggable: true,
                    searchable: true,
                    multiple: true,
                    label: 'name',
                    clearOnSelect: false,
                  }"
                  @select="onSelect"
                  @searchChange="findClients"
                />
                <p class="text-xs">
                  Обрано клієнтів для розсилки: {{ countSelectedClients }}
                </p>
              </div>
              <div class="col-lg-4">
                <h5>Телефон(и) отримувача(ів)</h5>
                <div class="input-group input-group-outline">
                  <textarea
                    v-model="company.instantMessageReceivers"
                    class="form-control"
                    placeholder="380671234567,380671323123"
                    rows="2"
                    type="text"
                    @input="autoResizeTextarea"
                    ref="descriptionRef2"
                    style="height: 75px"
                  />
                </div>
                <b class="text-xs">Вимоги щодо номеру телефону:</b>
                <p class="text-xs">
                  1. Номер телефону має бути у форматі 380<br />
                  2. Якщо ви хочете відправити одразу декільком отримувачам –
                  відокремте номери комою.<br />
                  Приклад: 380671234567,380671323123
                </p>
              </div>
            </div>
            <div class="w-50">
              <button
                class="btn bg-gradient-primary"
                type="button"
                @click="sendInstantMessage"
              >
                Відправити
              </button>
            </div>
          </div>
          <div class="d-lg-flex">
            <div class="mt-3">
              <h5 class="mb-0">Історія розсилок</h5>
              <p class="text-sm mb-0"></p>
              <p class="mb-0 text-sm"></p>
            </div>
          </div>
          <div class="table-responsive">
            <vue-good-table
              v-model:isLoading="isLoading"
              :columns="datatableColumns"
              :pagination-options="{
                enabled: true,
                perPage: tablePerPage,
                position: 'bottom',
                perPageDropdownEnabled: false,

                nextLabel: 'наст.',
                prevLabel: 'попер.',
                rowsPerPageLabel: 'записів на сторінці',
                ofLabel: 'із',
                pageLabel: 'сторінка',
                allLabel: 'всі',
              }"
              :rows="instantMessagesTableData.rows"
              :sort-options="{
                enabled: true,
                multipleColumns: false,
              }"
              :totalRows="instantMessagesTableData.totalRecords"
              compactMode
              mode="remote"
              responsive
              styleClass="vgt-table"
              theme="default"
              v-on:page-change="onInstantMessagesPageChange"
              v-on:sort-change="onSortChange"
              v-on:column-filter="onColumnFilter"
              v-on:per-page-change="onPerPageChange"
            >
              <template #table-row="props">
                <div v-if="props.column.field === 'createdAt'">
                  <div>
                    <router-link
                      :to="`/ecommerce/service-orders/${props.row._id}`"
                      class="mx-3"
                      data-bs-original-title="Переглянути"
                      data-bs-toggle="tooltip"
                      target="_self"
                    >
                      <span class="my-2 text-xs">{{
                        timestampToString(props.row.createdAt)
                      }}</span>
                    </router-link>
                  </div>
                </div>
                <div v-if="props.column.field === 'reminder.startTime'">
                  <div v-if="props.row.reminder?.startTime">
                    <router-link
                      :to="`/ecommerce/service-orders/${props.row._id}`"
                      class="mx-3"
                      data-bs-original-title="Переглянути"
                      data-bs-toggle="tooltip"
                      target="_self"
                    >
                      <span>
                        <span class="my-2 text-xs">{{
                          timestampToString(props.row.reminder.startTime)
                        }}</span>
                      </span>
                    </router-link>
                  </div>
                </div>

                <div v-if="props.column.field === 'purpose'">
                  <div v-if="props.row.reminder?.startTime">
                    <span class="my-2 text-xs">{{
                      tableFieldPurposeMapping(props.row)
                    }}</span>
                  </div>
                </div>
                <div v-if="props.column.field === 'reminder.text'">
                  <div v-if="props.row.reminder?.startTime">
                    <span class="my-2 text-xs">{{
                      props.row.reminder?.text
                    }}</span>
                  </div>
                </div>

                <div v-if="props.column.field === 'clientId.phoneNumber'">
                  <router-link
                    :to="`/ecommerce/clients/${props.row.clientId._id}`"
                    class="mx-3"
                    data-bs-original-title="Переглянути"
                    data-bs-toggle="tooltip"
                    target="_self"
                  >
                    <span class="my-2 text-xs">{{
                      props.row.recipient || props.row.clientId.phoneNumber
                    }}</span>
                  </router-link>
                </div>

                <div v-if="props.column.field === 'clientId'">
                  <router-link
                    :to="`/ecommerce/clients/${props.row.clientId._id}`"
                    class="mx-3"
                    data-bs-original-title="Переглянути"
                    data-bs-toggle="tooltip"
                    target="_self"
                  >
                    <span class="my-2 text-xs"
                      >{{ props.row.clientId.firstName }}
                      {{ props.row.clientId.lastName }}</span
                    >
                  </router-link>
                </div>

                <div v-if="props.column.field === 'reminder.status'">
                  <div v-if="props.row.reminder?.startTime">
                    <div
                      v-if="props.row.reminder?.status === 'internalQueued'"
                      class="badge bg-gradient-info"
                    >
                      Очікує на відправку
                    </div>
                    <div
                      v-if="props.row.reminder?.status === 'Queued'"
                      class="badge bg-gradient-info"
                    >
                      Очікує на відправку
                    </div>
                    <div
                      v-if="props.row.reminder?.status === 'Accepted'"
                      class="badge bg-gradient-info"
                    >
                      Очікує на відправку
                    </div>

                    <div
                      v-if="props.row.reminder?.status === 'Sent'"
                      class="badge bg-gradient-success"
                    >
                      Відправлено
                    </div>
                    <div
                      v-if="props.row.reminder?.status === 'Delivered'"
                      class="badge bg-gradient-success"
                    >
                      Відправлено
                    </div>
                    <div
                      v-if="props.row.reminder?.status === 'Read'"
                      class="badge bg-gradient-success"
                    >
                      Відправлено
                    </div>

                    <div
                      v-if="props.row.reminder?.status === 'Expired'"
                      class="badge bg-gradient-danger"
                    >
                      Помилка відправки
                    </div>
                    <div
                      v-if="props.row.reminder?.status === 'Rejected'"
                      class="badge bg-gradient-danger"
                    >
                      Помилка відправки
                    </div>
                    <div
                      v-if="props.row.reminder?.status === 'Unknown'"
                      class="badge bg-gradient-danger"
                    >
                      Помилка відправки
                    </div>
                    <div
                      v-if="props.row.reminder?.status === 'Failed'"
                      class="badge bg-gradient-danger"
                    >
                      Помилка відправки
                    </div>
                    <div
                      v-if="props.row.reminder?.status === 'Cancelled'"
                      class="badge bg-gradient-danger"
                    >
                      Відміна відправки
                    </div>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import setTooltip from "@/assets/js/tooltip.js";
import Spinner from "@/views/shared/Spinner.vue";

import { autoResizeTextarea } from "@/components/common/functions/autoResizeTextarea";

import api from "@/services/api";
import constants from "@/constants";
import * as _ from "lodash";
import { DateTime } from "luxon";
import Multiselect from "vue-multiselect";
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import SelectApp from "@/components/common/SelectApp.vue";
const dataLoaded = ref(false);
const store = useStore();

const descriptionRef1 = ref(null);
const descriptionRef2 = ref(null);

const company = ref({
  smsBalance: 0,
  smsSenderName: "",

  instantMessageText: "",
  instantMessageReceivers: "",
});
const charCount = computed(() => {
  return company.value?.instantMessageText?.length || 0;
});

const tablePerPage = ref(constants.defaultDatatablePerPage);
const tableData = ref({
  totalRecords: 0,
  rows: [],
});
const instantMessagesTableData = ref({
  totalRecords: 0,
  rows: [],
});
const isLoading = ref(false);
const serverParams = ref({
  columnFilters: {
    // a map of column filters example: {name: 'john', age: '20'}
  },
  sort: [
    {
      field: "createdAt", // example: 'name'
      type: "desc", // 'asc' or 'desc'
    },
  ],

  page: 1,
  perPage: constants.defaultDatatablePerPage,
});
const datatableColumns = ref([
  {
    label: "Дата створення",
    field: "createdAt",
    sortable: true,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-sm font-weight-normal text-center",
  },
  {
    label: "Дата відправки",
    field: "reminder.startTime",
    sortable: true,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal text-center",
  },
  {
    label: "Мета",
    field: "purpose",
    sortable: true,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal text-center",
  },
  {
    label: "Текст",
    field: "reminder.text",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal text-center",
  },
  {
    label: "Телефон",
    field: "clientId.phoneNumber",
    type: "string",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal text-center",
  },
  {
    label: "Клієнт",
    field: "clientId",
    type: "string",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal text-center",
  },
  {
    label: "Кількість (кредити)",
    field: "reminder.priceInCredits",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal text-center",
  },
  {
    label: "Статус",
    field: "reminder.status",
    sortable: true,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal text-center",
  },
]);

onMounted(async () => {
  company.value = await api.company.show();

  setTimeout(() => {
    autoResizeTextarea({
      target: descriptionRef1.value,
    });
    autoResizeTextarea({
      target: descriptionRef2.value,
    });
  }, 1000);

  await loadItems();

  dataLoaded.value = true;

  setTooltip();
});

// eslint-disable-next-line no-unused-vars
function tableFieldPurposeMapping(row) {
  if (row?.purpose) {
    return row.purpose;
  }
  return "Нагадування про запис";
}

function updateParams(newProps) {
  serverParams.value = Object.assign({}, serverParams.value, newProps);
}

async function onInstantMessagesPageChange(params) {
  updateParams({ instantMessagesPage: params.currentPage });
  await loadItems();
}

async function onPerPageChange(params) {
  updateParams({ perPage: params.currentPerPage });
  await loadItems();
}

async function onSortChange(params) {
  updateParams({
    page: 1,
    instantMessagesPage: 1,
    sort: [
      {
        type: params[0].type,
        field: params[0].field,
      },
    ],
  });
  await loadItems();
}

async function onColumnFilter(params) {
  updateParams(params);
  await loadItems();
}

async function loadItems() {
  const serviceOrders = await api.serviceOrders.list({
    limit: serverParams.value.perPage,
    offset: (serverParams.value.page - 1) * serverParams.value.perPage,

    sortField: _.get(serverParams.value, "sort[0].field"),
    sortDir: _.get(serverParams.value, "sort[0].type"),

    datatable: "y",
    sourcePage: "integration-reminders",
  });

  tableData.value.totalRecords = serviceOrders.totalRecords;
  tableData.value.rows = serviceOrders.rows;

  const instantMessageStatuses = await api.company.getInstantMessageStatuses({
    limit: serverParams.value.perPage,
    offset:
      (serverParams.value.instantMessagesPage - 1) * serverParams.value.perPage,
    datatable: "y",
  });

  instantMessagesTableData.value.totalRecords =
    instantMessageStatuses.totalRecords;
  instantMessagesTableData.value.rows = instantMessageStatuses.rows;
}

function timestampToString(input) {
  if (!input) {
    return "-";
  }
  const datetime = DateTime.fromISO(input);
  return datetime.setLocale("uk-UA").toFormat("dd.MM.yyyy HH:mm");
}

async function sendInstantMessage() {
  let concatedNumbers = [
    company.value.instantMessageReceivers,
    payLoadNumbers.value,
  ]
    .filter((item) => !!item)
    .join(",");

  try {
    const responseRaw = await api.company.sendInstantMessage(
      company.value.smsSenderName,
      concatedNumbers,
      company.value.instantMessageText
    );

    if (responseRaw.status === 402) {
      store.commit("addToast", {
        title: "Недостатньо смс-кредитів, поповніть, будь-ласка, баланс",
        type: "error",
      });

      return;
    }

    if (responseRaw.status !== 200) {
      store.commit("addToast", {
        title: "Виникла помилка. Спробуйте ще раз",
        type: "error",
      });

      return;
    }

    const dataBalance = await api.company.getInstantMessageBalance();
    company.value.smsBalance = dataBalance.smsBalance;

    await loadItems();

    store.commit("addToast", {
      title: "Надіслано SMS!",
    });
  } catch (error) {
    store.commit("addToast", {
      title: "Недостатньо смс-кредитів, поповніть, будь-ласка, баланс",
      type: "error",
    });
  }
}

function shouldShowMessage(smsObj) {
  if (!smsObj) {
    return false;
  }

  return smsObj.messageId || smsObj.status === "internalInsufficientFunds";
}

const RE_PHONE_NUMBER = /^\+?\d{12}$/;

function isValidPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return false;
  }

  if (!RE_PHONE_NUMBER.test(phoneNumber)) {
    return false;
  }
  return true;
}

const listAllClients = ref([]);

const selectedClients = ref([]);
const DEFAULT_CLIENT = {
  name: "Всі",
  code: "all",
};

const clientOptions = ref([]);
const isLoadingClients = ref(false);

async function findClients(query) {
  try {
    isLoadingClients.value = true;
    const response = await api.clients.list({
      limit: 300,
      search: query,
    });

    const formattedArr = response
      .filter((client) => isValidPhoneNumber(client.phoneNumber))
      .map((client) => ({
        code: client.phoneNumber,
        name: `${client.firstName} ${client.lastName} (${client.phoneNumber})`,
      }));

    if (formattedArr.length > 0) {
      clientOptions.value = [DEFAULT_CLIENT, ...formattedArr];
    } else {
      clientOptions.value = [...formattedArr];
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoadingClients.value = false;
  }
}

const isSelectedAll = computed(() => {
  return selectedClients.value.findIndex((item) => item.code === "all") >= 0;
});

async function onSelect(selectedOption, id) {
  if (selectedOption.code === "all") {
    const response = await api.clients.list({});

    listAllClients.value = response
      .filter((client) => isValidPhoneNumber(client.phoneNumber))
      .map((client) => ({
        code: client.phoneNumber,
        name: ``,
      }));
  }
}

const countSelectedClients = computed(() => {
  if (isSelectedAll.value) {
    return listAllClients.value.length;
  }
  return selectedClients.value.length;
});

const payLoadNumbers = computed(() => {
  if (isSelectedAll.value) {
    return listAllClients.value
      .map((client) => client.code.replace("+", ""))
      .join(",");
  }
  return selectedClients.value
    .map((client) => client.code.replace("+", ""))
    .join(",");
});

function clearSelect() {
  selectedClients.value = [];
}
</script>

<style scoped>
:deep(.multiselect__tags) {
  flex-wrap: wrap;
}
.infoBlock {
  background-color: #eef7ff;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 8px 4px 0 4px;
}
</style>
