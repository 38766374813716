<template>
  <div class="container-fluid" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>

  <div class="container-fluid" v-show="dataLoaded">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h6 class="mb-0">Тенанти</h6>
                <p class="text-sm mb-0">Список тенантів</p>
                <p class="mb-0 text-sm"></p>
              </div>
            </div>
          </div>

          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <vue-good-table
                mode="remote"
                :pagination-options="{
                  enabled: true,
                  // perPage: this.tablePerPage,
                  perPage: tablePerPage,
                  position: 'bottom',
                  perPageDropdownEnabled: false,

                  nextLabel: 'наст.',
                  prevLabel: 'попер.',
                  rowsPerPageLabel: 'записів на сторінці',
                  ofLabel: 'із',
                  pageLabel: 'сторінка',
                  allLabel: 'всі',
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: false,
                  // initialSortBy: {
                  //   field: 'createdAt',
                  //   type: 'desc',
                  // },
                }"
                :totalRows="tableData.totalRecords"
                :columns="datatableColumns"
                :rows="tableData.rows"
                v-model:isLoading="isLoading"
                v-on:page-change="onPageChange"
                v-on:sort-change="onSortChange"
                v-on:column-filter="onColumnFilter"
                v-on:per-page-change="onPerPageChange"
                v-on:row-click="onRowClick"
                theme="default"
                styleClass="vgt-table bordered"
                responsive
                compactMode
              >
                <template #table-row="props">
                  <div v-if="props.column.field === 'internalName'">
                    <span class="text-xs">{{ props.row.internalName }}</span>
                    <br />
                    <span class="text-xs">
                      (online mode: {{ props.row.info.onlineFormMode }})
                    </span>
                  </div>
                  <div
                    v-if="props.column.field === 'counters.staff'"
                    :class="{
                      'bg-warning':
                        props.row.counters.staff >
                        props.row.counters.hardLimits.staffMax,
                    }"
                  >
                    <strong>{{ props.row.counters.staff }}</strong> (limit is
                    {{ props.row.counters.hardLimits.staffMax }})
                  </div>
                  <div
                    v-if="props.column.field === 'counters.company.smsBalance'"
                  >
                    <span class="text-xs"
                      ><strong>{{
                        props.row.counters.company.smsBalance
                      }}</strong>
                    </span>
                  </div>
                  <div v-if="props.column.field === 'comment'">
                    <span class="text-xs">{{ props.row.comment }}</span>
                  </div>

                  <div v-if="props.column.field === 'isActive'">
                    <span
                      class="badge bg-gradient-success"
                      v-if="props.row.isActive"
                      >активний</span
                    >
                    <span
                      class="badge bg-gradient-danger"
                      v-if="!props.row.isActive"
                      >деактивований</span
                    >
                  </div>

                  <div v-if="props.column.field === 'subscriptionStatus'">
                    <SelectApp
                      v-model="subscriptionStatuses[props.row.name]"
                      :settings="{
                        allowEmpty: false,
                        options: subscriptionStatusOptions[props.row.name],
                        trackBy: 'value',
                        label: 'label',
                      }"
                      @select="setTenantSubscriptionStatus"
                      :disabled="isSubscriptionStatusUpdating"
                    ></SelectApp>
                  </div>

                  <div v-if="props.column.field === 'createdAt'">
                    <span class="my-2 text-xs">{{
                      timestampToString(props.row.createdAt)
                    }}</span>
                    <button
                      class="btn btn-sm btn-outline-danger mb-0 ms-2 text-xs"
                      type="button"
                      name="button"
                      data-bs-toggle="modal"
                      :data-bs-target="`#${modalParamsRemoveTenant.modalId}`"
                      @click="removeTenantPreHook(props.row)"
                    >
                      Видалити
                    </button>
                  </div>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <BmnCustomPopup :params="modalParamsRemoveTenant"></BmnCustomPopup>
</template>

<style scoped></style>

<script>
import api from "@/services/api";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";
import Spinner from "@/views/shared/Spinner.vue";
import SelectApp from "@/components/common/SelectApp.vue";
import constants from "@/constants";
import * as _ from "lodash";
import { DateTime } from "luxon";

export default {
  data() {
    return {
      dataLoaded: false,
      showDevFeatures: false,

      profile: {
        rolesMap: {
          isRoot: false,
          isOwner: false,
          isManager: false,
          isStaff: false,
        },
      },

      tenants: [],

      tablePerPage: constants.defaultDatatablePerPage,
      tableData: {
        totalRecords: 0,
        rows: [],
      },
      isLoading: false,
      serverParams: {
        columnFilters: {
          // a map of column filters example: {name: 'john', age: 20}
        },
        sort: [
          {
            field: "createdAt", // example: 'name'
            type: "desc", // 'asc' or 'desc'
          },
        ],

        page: 1,
        perPage: constants.defaultDatatablePerPage,
      },
      datatableColumns: [
        {
          label: "",
          field: "actions",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Назва",
          field: "internalName",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 filterName",
          tdClass: "text-xs font-weight-normal",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Пошук за назвою",
          //   // styleClass: "someClass",
          //   // filterFn: this.columnFilterFn,
          //   // trigger: 'enter',
          // },
        },
        {
          label: "Кількість - staff",
          field: "counters.staff",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 filterName",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "SMS (шт)",
          field: "counters.company.smsBalance",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 filterName",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Коментар",
          field: "comment",
          type: "string",
          sortable: true,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal ",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Пошук за коментарем",
          //   // styleClass: "someClass",
          //   // filterFn: this.columnFilterFn,
          //   // trigger: 'enter',
          // },
        },
        {
          label: "Статус",
          field: "isActive",
          type: "boolean",
          sortable: true,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-xs font-weight-normal text-center",
        },
        {
          label: "Статус",
          field: "subscriptionStatus",
          type: "string",
          sortable: true,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-xs font-weight-normal text-center",
        },
        {
          label: "Дата реєстрації",
          field: "createdAt",
          sortable: true,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
          tdClass: "text-xs font-weight-normal",
        },
        // { // TODO: show this field once backend returns valid values (currently they are hardcoded)
        //   label: "Останній візит",
        //   field: "lastVisitAt",
        //   sortable: false,
        //   thClass:
        //     "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
        //   tdClass: "text-xs font-weight-normal ",
        // },
      ],
      isSubscriptionStatusUpdating: false,
      subscriptionStatusOptions: {},
      subscriptionStatusMap: {
        trial: "TRIAL",
        subscribed: "СПЛАЧЕНО",
        waiting: "ОЧІКУЄ ОПЛАТИ",
      },

      subscriptionStatuses: {},

      modalParamsRemoveTenant: {
        modalId: "modal-default-1",
        modalTitle: "Видалити тенанта",
        modalDescription: "Ви впевнені, що хочете видалити тенанта?",
        modalButtonCancelText: "Ні, не варто",
        modalButtonConfirmText: "Так, видалити",
        data: {
          tenantId: null,
        },
        confirmHandler: async () => {
          if (!this.modalParamsRemoveTenant.data.tenantId) {
            return;
          }
          const result = await api.maintenance.removeTenant(
            this.modalParamsRemoveTenant.data.tenantId
          );
          await this.loadItems();
        },
        cancelHandler: () => {
          this.modalParamsRemoveTenant.data.tenantId = null;
          this.modalParamsRemoveTenant.data.tenantName = null;
        },
      },
    };
  },

  async created() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
    if (!this.showDevFeatures) {
      window.location.href = "/";
      return;
    }

    try {
      const user = await api.myprofile.show();

      this.profile.rolesMap = user.rolesMap;

      if (!this.profile.rolesMap.isRoot) {
        window.location.href = "/";
        return;
      }

      await this.loadItems();

      this.dataLoaded = true;
    } catch (error) {
      console.error(error);
    }
  },

  components: {
    Spinner,
    BmnCustomPopup,
    SelectApp,
  },

  methods: {
    onRowClick() {},

    removeTenantPreHook(tenant) {
      this.modalParamsRemoveTenant.data.tenantId = tenant._id;
      this.modalParamsRemoveTenant.data.tenantName = tenant.internalName;
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    async onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      await this.loadItems();
    },

    async onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      await this.loadItems();
    },

    async onSortChange(params) {
      this.updateParams({
        page: 1,
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      await this.loadItems();
    },

    async onColumnFilter(params) {
      this.updateParams(params);
      await this.loadItems();
    },

    async loadItems() {
      const params = {
        limit: this.serverParams.perPage,
        offset: (this.serverParams.page - 1) * this.serverParams.perPage,

        sortField: _.get(this.serverParams, "sort[0].field"),
        sortDir: _.get(this.serverParams, "sort[0].type"),

        datatable: "y",
      };

      if (this.serverParams.columnFilters) {
        for (const [paramKey, paramValue] of Object.entries(
          this.serverParams.columnFilters
        )) {
          params[paramKey] = paramValue;
        }
      }

      const result = await api.maintenance.getTenants(params);

      // this.tableData.totalRecords = result.totalRecords;
      // this.tableData.rows = result.rows;
      this.tableData.totalRecords = result.tenants.length;
      this.tableData.rows = result.tenants;

      for (const tenant of this.tableData.rows) {
        this.subscriptionStatusOptions[tenant.name] = [
          {
            value: "trial",
            label: "TRIAL",
            tenantId: tenant._id,
          },
          {
            value: "subscribed",
            label: "СПЛАЧЕНО",
            tenantId: tenant._id,
          },
          {
            value: "waiting",
            label: "ОЧІКУЄ ОПЛАТИ",
            tenantId: tenant._id,
          },
        ];

        if (tenant.subscriptionStatus === "trial") {
          this.subscriptionStatuses[tenant.name] = {
            value: "trial",
            label: "TRIAL",
            tenantId: tenant._id,
          };
          continue;
        }

        if (tenant.subscriptionStatus === "subscribed") {
          this.subscriptionStatuses[tenant.name] = {
            value: "subscribed",
            label: "СПЛАЧЕНО",
            tenantId: tenant._id,
          };
          continue;
        }

        this.subscriptionStatuses[tenant.name] = {
          value: "waiting",
          label: "ОЧІКУЄ ОПЛАТИ",
          tenantId: tenant._id,
        };
      }
    },

    timestampToString(input) {
      if (!input) {
        return "-";
      }
      const datetime = DateTime.fromISO(input);
      return datetime
        .setLocale("uk-UA")
        .toLocaleString({ month: "long", day: "numeric", year: "numeric" });
    },

    async setTenantSubscriptionStatus(input) {
      this.isSubscriptionStatusUpdating = true;
      console.log(input.tenantId, input.value);

      try {
        await api.maintenance.setTenantSubscriptionStatus(
          input.tenantId,
          input.value
        );
        this.$store.commit("addToast", {
          title: "Статус змінено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      } finally {
        this.isSubscriptionStatusUpdating = false;
      }
    },
  },
};
</script>
