<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <div class="card">
            <!-- monobank acquiring -->
            <div class="card mt-4 pb-3" id="additional-settings">
              <div class="card-body">
                <h6>Plata by mono</h6>
                <div class="row mt-3">
                  <div class="col-lg-8">
                    <span class="text-sm font-weight-normal">
                      <strong>Увімкнути plata by mono еквайринг</strong>
                    </span>
                  </div>
                  <div class="col-lg-4">
                    <div class="row">
                      <div class="col-6 hours">
                        <div class="input-group input-group-outline">
                          <div class="col-2 form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="branchTemplate.paymentMonobankEnabled"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-lg-8">
                    <span class="text-sm font-weight-normal">
                      <strong>Plata by mono еквайринг токен.</strong> За
                      допомогою plata by mono acquiring ви можете приймати
                      оплату або переодплату за ваші послуги. Для підключення
                      bookmenow до вашого профілю в monobank acquiring,
                      будь-ласка, вкажіть ваш Токен для інтернет-еквайрингу з
                      особистого кабінету в monobank.</span
                    >
                  </div>
                  <div class="col-lg-4">
                    <div class="row">
                      <div class="col-12 hours">
                        <label>Токен</label>
                        <div class="input-group input-group-outline">
                          <input
                            type="password"
                            min="1"
                            class="form-control"
                            :disabled="!branchTemplate.paymentMonobankEnabled"
                            v-model="branchTemplate.paymentMonobankToken"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- monobank acquiring finish -->
          </div>
        </div>
      </div>
      <div class="fixed-bottom block">
        <div class="row">
          <div class="col-10"></div>
          <div class="col-lg-2">
            <div class="mx-4">
              <button
                type="button"
                class="block btn bg-gradient-primary"
                :disabled="!presaveValidation()"
                @click="save"
              >
                Зберегти
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
</style>

<script>
import api from "@/services/api";
import Spinner from "@/views/shared/Spinner.vue";

export default {
  name: "integrations-monobank",

  async created() {
    this.company = await api.company.show();
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");

    this.branchTemplate = (await api.branches.list())[0];
  },

  mounted() {
    this.dataLoaded = true;
  },

  data() {
    return {
      showDevFeatures: false,
      dataLoaded: false,
      company: {
        crmMode: "",
      },

      branchTemplate: {
        paymentMonobankEnabled: false,
        paymentMonobankToken: null,
      },
    };
  },

  components: {
    Spinner,
  },
  methods: {
    isValidPaymentMonobankToken() {
      if (
        this.branchTemplate.paymentMonobankEnabled &&
        !this.branchTemplate.paymentMonobankToken
      ) {
        return false;
      }
      return true;
    },

    presaveValidation() {
      if (!this.isValidPaymentMonobankToken()) {
        return false;
      }

      return true;
    },

    async save() {
      if (!this.presaveValidation()) {
        return;
      }

      let lpEnabled = this.branchTemplate.paymentLiqPayEnabled;
      if (this.branchTemplate.paymentMonobankEnabled) {
        lpEnabled = false;
      }

      try {
        await api.branches.updateOne(this.branchTemplate._id, {
          paymentMonobankEnabled: this.branchTemplate.paymentMonobankEnabled,
          paymentMonobankToken: this.branchTemplate.paymentMonobankToken,
          paymentLiqPayEnabled: lpEnabled,
        });

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },
  },
};
</script>
