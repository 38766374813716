<template>
  <div class="container-fluid my-3 py-3">
    <div class="row mb-5">
      <div class="col-lg-10 mt-lg-0 mx-auto">
        <!-- widget Info -->
        <div class="card" id="widget">
          <div class="card-header">
            <h5>Віджет</h5>
          </div>
          <div class="card-body pt-0">
            <p class="text-sm">
              Додайте окремо ці два коди на ваш сайт в розділ <b>head</b>, щоб
              на ньому зʼявилась кнопка "Онлайн бронь" у вигляді віджету.
            </p>
            <div
              class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4 pt-4 position-relative"
            >
              <span
                class="cursor-pointer position-absolute top-4 end-2 d-inline-flex align-items-center justify-content-center"
              >
                <i
                  class="material-icons text-lg cursor-pointer"
                  @click="
                    copyToClipboard(
                      `<script src=&quot;https://bmn-production.s3.eu-central-1.amazonaws.com/f/widgetForm/widget.min.js&quot; charset=&quot;UTF-8&quot;></script>`
                    )
                  "
                >
                  copy
                </i>
              </span>
              <pre v-pre class="code-style">
&lt;script src="https://bmn-production.s3.eu-central-1.amazonaws.com/f/widgetForm/widget.min.js" charset="UTF-8"&gt;&lt;/script&gt;
              </pre>
            </div>
            <div
              class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4 pt-4 position-relative"
            >
              <span
                class="cursor-pointer position-absolute top-4 end-2 d-inline-flex align-items-center justify-content-center"
              >
                <i
                  class="material-icons text-lg cursor-pointer"
                  @click="
                    copyToClipboard(
                      `<script>
  var bmnWidgetConfig = {
    clientId: &quot;${tenantName}&quot;,
    position: &quot;bottom right&quot;
  };
</script>`
                    )
                  "
                >
                  copy
                </i>
              </span>
              <pre class="code-style">
&lt;script&gt;
  var bmnWidgetConfig = {
    clientId: "{{ tenantName }}",
    position: "bottom right"
  };
&lt;/script&gt;
              </pre>
            </div>
          </div>
        </div>
        <!-- widget finish -->
      </div>
    </div>
  </div>
</template>
<script setup>
import useClipboard from "vue-clipboard3";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const tenantName = ref(null);

async function copyToClipboard(value) {
  const { toClipboard } = useClipboard();
  try {
    await toClipboard(value);
    store.commit("addToast", {
      title: "Скопійовано",
    });
  } catch (error) {
    console.error(error);
  }
}

onMounted(() => {
  let tenantNameLocal;
  try {
    const localUser = localStorage.getItem("localUser");
    if (localUser) {
      const parsed = JSON.parse(localUser);
      tenantNameLocal = parsed.tenantName;
      store.commit("setTenantName", tenantNameLocal);
    }
  } catch (error) {
    console.error(error);
  }

  tenantName.value = store.state.tenantName;
});
</script>

<style lang="scss">
.code-style {
  color: #d63384;
}
</style>
