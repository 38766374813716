<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>

  <div class="container-fluid" v-show="dataLoaded">
    <div class="row mb-5">
      <div class="col-lg-9 mt-lg-0 mx-auto">
        <!-- Нагадування про бронь -->
        <div class="card mt-2 pb-3" id="reminder">
          <div class="card-header">
            <div class="row">
              <div class="col-11">
                <h6 v-if="company.crmMode !== 'hotel'">
                  SMS нагадування про запис
                </h6>
                <h6 v-if="company.crmMode === 'hotel'">
                  SMS нагадування про бронь
                </h6>
              </div>
              <div class="col-1">
                <div class="form-check form-switch">
                  <input
                    v-model="company.remindBeforeEnabled"
                    class="form-check-input"
                    type="checkbox"
                  />
                </div>
              </div>
              <div class="w-85">
                <p class="text-sm mb-0" v-if="company.crmMode === 'hotel'">
                  Встановіть час, за скільки до заселення відправити нагадування
                </p>
                <p class="text-sm mb-0" v-if="company.crmMode !== 'hotel'">
                  Встановіть час, за скільки до запису відправити нагадування
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <label>Нагадати за:</label>
              <SelectApp
                class="form-control"
                v-model="selectedDurationOption"
                :settings="{
                  allowEmpty: false,
                  options: durationOptions,
                  trackBy: 'value',
                  label: 'label',
                }"
                @select="setDurationOption"
              ></SelectApp>
            </div>
          </div>
        </div>
        <!-- Нагадування про бронь finish -->

        <!-- Підтвердження про запис на сторінці з адмін панелі -->
        <div class="card mt-2 pb-3" id="notifications">
          <div class="card-header">
            <div class="row">
              <div class="col-11">
                <h6 v-if="company.crmMode !== 'hotel'">
                  SMS про створений запис в адмін панелі
                </h6>
                <h6 v-if="company.crmMode === 'hotel'">
                  SMS про створену бронь в адмін панелі
                </h6>
              </div>
              <div class="col-1">
                <div class="form-check form-switch">
                  <input
                    v-model="company.smsNotifyOnNewServiceOrderFromApp"
                    class="form-check-input"
                    type="checkbox"
                  />
                </div>
              </div>
              <div class="w-85">
                <p class="text-sm mb-0">
                  Увімкніть, щоб сповіщення відправлялись за замовчуваням для
                  бронювань створених в адмін панелі. Вимкніть, і тоді при
                  створенні бронювання сповіщення буде вимкнено, але його можна
                  буде увімкнути для конкретного бронювання
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Підтвердження про запис на сторінці з адмін панелі finish -->

        <!-- Підтвердження про запис на сторінці онлайн броннювання -->
        <div id="notifyOnCreated" class="card mt-2 pb-3">
          <div class="card-header">
            <div class="row">
              <div class="col-11">
                <h6 v-if="company.crmMode !== 'hotel'">
                  SMS про створений запис зі сторінки онлайн запису
                </h6>
                <h6 v-if="company.crmMode === 'hotel'">
                  SMS про створену бронь зі сторінки онлайн бронювання
                </h6>
              </div>
              <div class="col-1">
                <div class="form-check form-switch">
                  <input
                    v-model="company.smsNotifyOnNewServiceOrderFromClient"
                    class="form-check-input"
                    type="checkbox"
                  />
                </div>
              </div>
              <div class="w-85">
                <p class="text-sm mb-0">
                  SMS з підтвердженням відправлятиметься одразу після створення
                  бронювання.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Підтвердження про запис на сторінці онлайн броннювання finish -->

        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  @click="save"
                >
                  Зберегти
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}

.hours,
.minutes {
  max-width: 80px;
  min-width: 80px;
}
</style>

<script>
import Spinner from "@/views/shared/Spinner.vue";
import SelectApp from "@/components/common/SelectApp.vue";

import config from "@/config";
import api from "@/services/api";
import timeDisplay from "@/utils/timeDisplay";

import range from "lodash/range";

const API_URL_BASE = config.API_URL_BASE;

export default {
  name: "settings-sms-settings",

  components: {
    Spinner,
    SelectApp,
  },

  data() {
    return {
      dataLoaded: false,
      showDevFeatures: false,

      durationOptions: [],
      selectedDurationOption: {},

      company: {
        smsNotifyOnNewServiceOrderFromClient: false,
        remindBeforeEnabled: false,
        smsNotifyOnNewServiceOrderFromApp: false,
        remindBeforeMinutes: 60 * 24, // 24 hours
      },
    };
  },

  async beforeMount() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");

    this.company = await api.company.show();
  },

  mounted() {
    setTimeout(() => {
      this.durationOptions = [1, 2, 3, 4, 5, 24, 48, 72].map((value) => {
        return {
          value: value * 60,
          label: timeDisplay.minutesToHumanFormat(value * 60),
        };
      });

      if (!this.company.remindBeforeMinutes) {
        this.company.remindBeforeMinutes = 300;
      }

      this.selectedDurationOption = this.durationOptions.find(
        (option) => option.value === this.company.remindBeforeMinutes
      );

      this.dataLoaded = true;
    }, 500);
  },

  methods: {
    async save() {
      try {
        await api.company.update({
          smsNotifyOnNewServiceOrderFromClient:
            this.company.smsNotifyOnNewServiceOrderFromClient,
          remindBeforeEnabled: this.company.remindBeforeEnabled,
          remindBeforeMinutes: this.company.remindBeforeMinutes,
          smsNotifyOnNewServiceOrderFromApp:
            this.company.smsNotifyOnNewServiceOrderFromApp,
        });

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    async removePhoto(name) {
      if (!this.company[name]) {
        console.warn("no photo for removing");
        return;
      }

      const payload = {
        key: this.company[name].key,
      };

      const API_URL = `${API_URL_BASE}/settings/staff/removePhoto`;
      const options = {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          "x-access-token": localStorage.getItem("accessToken"),
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(API_URL, options);
      await response.json();

      this.company[name].key = null;
      this.company[name].url = null;
    },

    getLabelStaffChooseLayoutSize() {
      const tenantName = this.$store.state.tenantName;
      switch (tenantName) {
        case "bookmenow":
        case "travelkayak":
        case tenantName === "zdorovenki-buly":
        case tenantName === "nature-oasis":
        case tenantName === "ecopark-medvino":
        case tenantName === "driada":
        case tenantName === "family-evans-spa":
          return "Обрати ресурс";
        case "Oblaka":
        case "hotel":
        case "obuhovsky":
        case "motel":
          return "Обрати номер";
        default:
          return "Обрати майстра";
      }
    },

    setDurationOption(input) {
      this.company.remindBeforeMinutes = input.value;
    },
  },
};
</script>
