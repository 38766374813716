import Dropzone from "dropzone";
import Choices from "choices.js";
import Spinner from "@/views/shared/Spinner.vue";
import { DateTime } from "luxon";
import setNavPills from "@/assets/js/nav-pills.js";

import config from "@/config";
import api from "@/services/api";
import constants from "@/constants";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";
import checkUserRole from "@/services/checkUserRole";
import { autoResizeTextarea } from "@/components/common/functions/autoResizeTextarea";

const API_URL_BASE = config.API_URL_BASE;

const mapAmenities = (amenitiesList) => {
  if (!amenitiesList) {
    return {};
  }

  return amenitiesList.reduce((result, amenity) => {
    if (!(amenity.sectionKey in result)) {
      result[amenity.sectionKey] = [];
    }

    result[amenity.sectionKey].push(amenity);
    return result;
  }, {});
};

export default {
  name: "settings-staff-details",

  async beforeMount() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");

    this.company = await api.company.show();
    this.branch = (await api.branches.list())[0];

    const filterIncludeRemoved = {
      includeRemoved: "1",
    };
    this.positions = await api.positions.list(filterIncludeRemoved);

    if (this.$route.params.id) {
      this.mode = "edit";
      this.staffTemplate = await api.staff.findOne(this.$route.params.id);

      this.staffTemplate.checkinRules = this.staffTemplate.checkinRules || {
        guestsMax: 2,
        checkinTime: {
          from: "15:00",
          to: "21:00",
        },
        checkoutTime: "12:00",
        smartCheckin: {
          enabled: false,
          iconName: "CheckInIcon",
          text: "",
          description: "",
        },
        petsFriendly: {
          enabled: false,
          iconName: "PetsIcon",
          text: "",
          description: "",
        },
      };

      this.staffTemplate.cancellationPolicy = this.staffTemplate
        .cancellationPolicy || {
        enabled: false,
        rules: [
          {
            enabled: true,
            rule: "greaterThen",
            days: 10,
            returnPercentage: 100,
          },
          {
            enabled: true,
            rule: "lessThen",
            days: 10,
            returnPercentage: 50,
          },
          {
            enabled: true,
            rule: "lessThen",
            days: 3,
            returnPercentage: 0,
          },
        ],
      };

      this.additionalServices = await api.staff.getAdditionalServices(
        this.$route.params.id
      );

      await this.refreshSyncUrls(this.$route.params.id);

      if (this.staffTemplate.phoneNumber) {
        this.phoneNumberExists = true;
      }

      if (checkUserRole("owner") || checkUserRole("root")) {
        this.isOwner = true;
      }

      this.amenities = mapAmenities(this.staffTemplate.amenities);
      if (Object.keys(this.amenities).length === 0) {
        this.amenities = await api.staff.getDefaultAmenities();
      }
    } else {
      this.mode = "create";
      this.amenities = await api.staff.getDefaultAmenities();
    }

    this._filterPositions();

    setTimeout(() => {
      const selector = document.getElementById("choices-positions");
      if (selector) {
        const formattedPositions = this.positions.map((item) => {
          return {
            value: item._id,
            label: item.name,
            selected: item._id === this.staffTemplate.positionId,
            disabled: item.isRemoved,
          };
        });
        if (formattedPositions.length && !this.staffTemplate.positionId) {
          formattedPositions[0].selected = true;
        }

        const choiceApi = new Choices(selector, {
          allowHTML: false,
          removeItemButton: false,
          searchEnabled: false,
          choices: formattedPositions,
        });

        this.staffTemplate.positionId = choiceApi.getValue().value;
      }
    });

    this.dataLoaded = true;

    setTimeout(autoResizeTextarea, 1000, {
      target: this.$refs.description,
    });
  },

  async mounted() {
    setNavPills();

    const dropzoneOptions = {
      url: `${API_URL_BASE}/settings/staff/uploadPhoto`,
      method: "POST",
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
      },
      paramName: "file",
      maxFiles: 1,
      maxFilesize: 2 * 1024 * 1024, // 2 MB
      createImageThumbnails: true,
      addRemoveLinks: false,
    };
    const dropzone = new Dropzone("#staffPhoto", dropzoneOptions);

    dropzone.on("success", (file, result) => {
      this.staffTemplate.photo.key = result.photoKey;
      this.staffTemplate.photo.url = result.photoUrl;

      dropzone.removeFile(file);
    });

    dropzone.on("error", (file, message) => {
      console.warn(message);
    });

    const dropzoneOptionsMultiplePhotos = {
      url: `${API_URL_BASE}/settings/staff/${this.$route.params.id}/uploadPhotos`,
      method: "POST",
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
      },
      paramName: "files",

      autoProcessQueue: true,
      uploadMultiple: true,
      maxFiles: 10,
      parallelUploads: 10,
      maxFilesize: 5 * 1024 * 1024, // 5 MB

      createImageThumbnails: true,
      addRemoveLinks: false,
    };
    const dropzoneMultiplePhotos = new Dropzone(
      "#staffPhotos",
      dropzoneOptionsMultiplePhotos
    );

    // document.getElementById("uploadButton").click(() => {
    //   dropzoneMultiplePhotos.processQueue();
    // });

    dropzoneMultiplePhotos.on("successmultiple", (files, response) => {
      dropzoneMultiplePhotos.removeAllFiles();
      this.staffTemplate.photos = response;
    });

    dropzoneMultiplePhotos.on("errormultiple", (files, response) => {
      console.warn(files);
      console.warn(response);
    });
  },

  data() {
    return {
      showDevFeatures: false,
      mode: "", // create | edit
      dataLoaded: false,

      cancellationPolicy: {
        enabled: false,
        rules: [
          {
            enabled: true,
            rule: "greaterThen",
            days: 10,
            returnPercentage: 100,
          },
          {
            enabled: true,
            rule: "lessThen",
            days: 10,
            returnPercentage: 50,
          },
          {
            enabled: true,
            rule: "lessThen",
            days: 3,
            returnPercentage: 0,
          },
        ],
      },

      company: {
        crmMode: "",
        isChannelManagerEnabled: false,
      },
      branch: {
        paymentMonobankEnabled: false,
      },

      staffTemplate: {
        isActive: true,
        name: "",
        phoneNumber: "",
        positionId: null,
        photo: {
          key: "",
          url: "",
        },
        photos: [],
        onlineBooking: true,
        description: "",

        checkinRules: {
          guestsMax: 2,
          checkinTime: {
            from: "15:00",
            to: "21:00",
          },
          checkoutTime: "12:00",
          smartCheckin: {
            enabled: false,
            iconName: "CheckInIcon",
            text: "",
            description: "",
            code: "",
          },
          petsFriendly: {
            enabled: false,
            iconName: "PetsIcon",
            text: "",
            description: "",
          },
          children: {
            enabled: false,
            iconName: "KidsIcon",
            text: "",
            description: "",
          },
        },

        cancellationPolicy: {
          enabled: false,
          rules: [
            {
              enabled: true,
              rule: "greaterThen",
              days: 10,
              returnPercentage: 100,
            },
            {
              enabled: true,
              rule: "lessThen",
              days: 10,
              returnPercentage: 50,
            },
            {
              enabled: true,
              rule: "lessThen",
              days: 3,
              returnPercentage: 0,
            },
          ],
        },

        isRemoved: false,
      },
      amenities: {},
      positions: [],
      phoneNumberExists: false,
      isOwner: false,
      password1: "",
      password2: "",

      additionalServices: [],
      syncUrlsImport: [],
      syncUrlExport: null,

      syncUrlImportTemplate: {
        _id: null,
        direction: "import",
        name: null,
        url: null,
        isActive: true,
      },

      // guestsMaxOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      guestsMaxOptions: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
        39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
        57, 58, 59, 60,
      ],

      modalParamsRemoveStaff: {
        modalId: "modal-default-3",
        modalTitle: "Видалення",
        modalDescription: "Ви впевнені, що хочете видалити?",
        modalButtonCancelText: "Відмінити",
        modalButtonConfirmText: "Видалити",
        data: {},
        confirmHandler: async () => {
          try {
            await api.staff.removeOne(this.$route.params.id);

            this.$store.commit("addToast", {
              title: "Збережено",
            });

            await this.$router.push("/team/staff");
          } catch (error) {
            this.$store.commit("addToast", {
              title: "Виникла помилка. Спробуйте ще раз",
              type: "error",
            });
          }
        },
      },

      modalParamsImportSyncUrl: {
        modalId: "modal-default-8",
        modalTitle: "Імпорт календаря",
        modalDescription:
          "Імпорт календаря у iCal форматі - для синхронізації бронювань з інших систем (Airbnb, Booking та інші) до Bookmenow",
        modalButtonCancelText: "Відміна",
        modalButtonConfirmText: "Зберегти",
        isSuccess: true,
        confirmButtonColor: "info",
        data: {},
        confirmHandler: async () => {
          try {
            const staffId = this.$route.params.id;

            if (this.syncUrlImportTemplate._id) {
              await api.staff.updateSyncUrl(
                staffId,
                this.syncUrlImportTemplate
              );
            } else {
              await api.staff.addSyncUrl(staffId, this.syncUrlImportTemplate);
            }

            await this.refreshSyncUrls(staffId);

            this.$store.commit("addToast", {
              title: "Збережено",
            });

            this.syncUrlImportTemplate._id = null;
            this.syncUrlImportTemplate.name = null;
            this.syncUrlImportTemplate.url = null;
          } catch (error) {
            this.$store.commit("addToast", {
              title: "Виникла помилка. Спробуйте ще раз",
              type: "error",
            });
          }
        },
        cancelHandler: () => {
          this.syncUrlImportTemplate._id = null;
          this.syncUrlImportTemplate.name = null;
          this.syncUrlImportTemplate.url = null;
        },
        confirmButtonIsValid: () => {
          return (
            this.syncUrlImportTemplate.name && this.syncUrlImportTemplate.url
          );
        },
      },

      modalParamsRemoveSyncUrl: {
        modalId: "modal-default-9",
        modalTitle: "Видалити зовнішній календар",
        modalDescription: "Видалити зовнішній календар",
        modalButtonCancelText: "Закрити",
        modalButtonConfirmText: "Видалити",
        data: {
          id: null,
        },
        confirmHandler: async (data) => {
          try {
            await api.staff.removeSyncUrl(this.$route.params.id, data.id);

            await this.refreshSyncUrls(this.$route.params.id);

            this.$store.commit("addToast", {
              title: "Збережено",
            });
          } catch (error) {
            this.$store.commit("addToast", {
              title: "Виникла помилка. Спробуйте ще раз",
              type: "error",
            });
          }
        },
      },

      modalParamsExportSyncUrl: {
        modalId: "modal-default-10",
        modalTitle: "Експорт календаря",
        modalDescription:
          "Експорт календаря у iCal форматі - для синхронізації бронювань bookmenow до інших систем (Airbnb, booking.com та інших). Скопіюйте посилання і використайте його у відповідному сервісі.",
        modalButtonCancelText: "Відміна",
        modalButtonConfirmText: "ОК",
        isSuccess: true,
        confirmButtonColor: "info",
        data: {},
        confirmHandler: async () => {},
        cancelHandler: () => {},
        confirmButtonIsValid: () => {
          return true;
        },
      },
    };
  },

  components: {
    BmnCustomPopup,
    Spinner,
  },

  methods: {
    autoResizeTextarea,

    isValidSave() {
      return true;
    },

    _filterPositions() {
      if (this.mode === "create") {
        this.positions = this.positions.filter((position) => {
          return !position.isRemoved;
        });
      } else {
        const currentPosition = this.positions.find((position) => {
          return position._id === this.staffTemplate.positionId;
        });

        this.positions = this.positions.filter((position) => {
          if (!position.isRemoved) {
            return true;
          }
          if (position._id === currentPosition?._id) {
            return true;
          }
          return false;
        });
      }
    },

    async save() {
      this.staffTemplate.amenities = Object.values(this.amenities).reduce(
        (result, amenitiesList) => {
          return result.concat(amenitiesList);
        }
      );

      try {
        if (this.mode === "create") {
          await api.staff.create(this.staffTemplate);
        } else if (this.mode === "edit") {
          await api.staff.updateOne(this.$route.params.id, this.staffTemplate);
        }

        this.$store.commit("addToast", {
          title: "Збережено",
        });

        await this.$router.push("/team/staff");
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    async resetPassword() {
      if (!this.isValidPassword()) {
        return false;
      }

      const update = {
        password: this.password1,
      };

      try {
        await api.staff.resetPassword(this.$route.params.id, update);

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }

      await this.$router.push("/team/staff");
    },

    isValidPassword() {
      if (this.password1 && this.password1 === this.password2) {
        return true;
      }
      return false;
    },

    async removePhoto() {
      const payload = {
        key: this.staffTemplate.photo.key,
        url: this.staffTemplate.photo.url,
      };
      const API_URL = `${API_URL_BASE}/settings/staff/removePhoto`;
      const options = {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          "x-access-token": localStorage.getItem("accessToken"),
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(API_URL, options);
      await response.json();

      this.staffTemplate.photo.key = null;
      this.staffTemplate.photo.url = null;
    },

    async removePhotoAdditional(additionalPhotoId) {
      const API_URL = `${API_URL_BASE}/settings/staff/${this.$route.params.id}/removePhotoAdditional/${additionalPhotoId}`;
      const options = {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          "x-access-token": localStorage.getItem("accessToken"),
        },
      };

      const response = await fetch(API_URL, options);
      this.staffTemplate.photos = await response.json();
    },

    async refreshSyncUrls(staffId) {
      if (!staffId) {
        return;
      }

      const syncUrls = await api.staff.getSyncUrls(staffId);

      const syncUrlExport = syncUrls.find((item) => {
        return item.direction === "export";
      });
      this.syncUrlsImport = syncUrls.filter((item) => {
        return item.direction === "import";
      });

      if (syncUrlExport) {
        this.syncUrlExport = syncUrlExport.url;
      }
    },

    async addSyncUrl() {
      this.modalParamsImportSyncUrl.modalTitle = "Додати зовнішній календар";
      this.modalParamsImportSyncUrl.modalDescription =
        "Додати зовнішній календар:";

      this.syncUrlImportTemplate._id = null;
      this.syncUrlImportTemplate.name = null;
      this.syncUrlImportTemplate.url = null;
    },
    async updateSyncUrl(syncUrl) {
      this.modalParamsImportSyncUrl.modalTitle = `Редагувати зовнішній календар: ${syncUrl.name}`;
      this.modalParamsImportSyncUrl.modalDescription = `Редагувати зовнішній календар: ${syncUrl.name}`;
      this.syncUrlImportTemplate._id = syncUrl._id;
      this.syncUrlImportTemplate.name = syncUrl.name;
      this.syncUrlImportTemplate.url = syncUrl.url;
    },
    async removeSyncUrl(id, name) {
      this.modalParamsRemoveSyncUrl.modalTitle = `Ви впевнені, що хочете видалити зовнішній календар: ${name}?`;
      this.modalParamsRemoveSyncUrl.modalDescription = `Ви впевнені, що хочете видалити зовнішній календар: ${name}?`;
      this.modalParamsRemoveSyncUrl.data.id = id;
    },

    showDateDetails(date) {
      if (!date) {
        return "-";
      }

      // TODO: fix timezone offset (currently show 3 hours behind)
      const dayObject = DateTime.fromISO(date).setLocale("uk-UA");
      return dayObject.toFormat("dd MMMM yyyy HH:mm");
    },

    getLabelDescriptionRemove() {
      const tenantName = this.$store.state.tenantName;
      switch (true) {
        case tenantName === "bookmenow":
        case tenantName === "travelkayak":
        case tenantName === "zdorovenki-buly":
        case tenantName === "nature-oasis":
        case tenantName === "ecopark-medvino":
        case tenantName === "greenfire-spa":
        case tenantName === "hatchyna-spa":
        case tenantName === "example-spa":
        case tenantName === "driada":
        case tenantName === "family-evans-spa":
          return `Коли ви видалите Ресурс, до нього більше не зможуть записатись, він не буде відображатись у календарі,
          але вся історія відвідувань буде збережена в аналітиці.`;
        default:
          return `Коли ви видалите майстра, до нього більше не зможуть записатись, він не буде відображатись у календарі,
          але вся історія відвідувань буде збережена в аналітиці.`;
      }
    },
  },
};
