<template>
  <div class="container-fluid" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>

  <div class="container-fluid" v-show="dataLoaded">
    <div class="justify-content-between">
      <div class="row">
        <div class="col-6">
          <router-link
            to="/ecommerce/clients/new"
            class="btn btn-icon bg-gradient-primary"
          >
            + Клієнт
          </router-link>
        </div>
        <div class="col-6 text-end" v-if="showDevFeatures">
          <div class="dropdown d-inline">
            <a
              href="javascript:;"
              class="btn btn-outline-dark dropdown-toggle btn-sm mx-1"
              data-bs-toggle="dropdown"
              id="navbarDropdownMenuLink2"
            >
              Всі
            </a>
            <ul
              class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownMenuLink2"
              data-popper-placement="left-start"
            >
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;"
                  >Всі</a
                >
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;"
                  >Загублені</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="customView.metric" class="alert alert-warning text-white">
        <span v-if="customView.metric === 'clientsNew'">
          <strong>Нові клієнти</strong>
        </span>
        <span v-if="customView.metric === 'clientsReturned'">
          <strong>Повторні візити клієнтів</strong>
        </span>
        <span v-if="customView.metric === 'clientsLost'">
          <strong>Втрачені клієнти</strong>
        </span>
        <span> - {{ customView.period }} </span>
        <span class="custom-view-close" @click="clearCustomViews">
          <button type="button" class="btn-close" aria-label="Close"></button>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h6 class="mb-0">Клієнти</h6>
                <p class="text-sm mb-0">Ваша клієнтська база тут</p>
                <p class="mb-0 text-sm"></p>
              </div>
            </div>
          </div>

          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <vue-good-table
                mode="remote"
                :pagination-options="{
                  enabled: true,
                  // perPage: this.tablePerPage,
                  perPage: tablePerPage,
                  position: 'bottom',
                  perPageDropdownEnabled: false,

                  nextLabel: 'наст.',
                  prevLabel: 'попер.',
                  rowsPerPageLabel: 'записыв на сторінці',
                  ofLabel: 'із',
                  pageLabel: 'сторінка',
                  allLabel: 'всі',
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: false,
                  // initialSortBy: {
                  //   field: 'createdAt',
                  //   type: 'desc'
                  // }
                }"
                :totalRows="tableData.totalRecords"
                :columns="datatableColumns"
                :rows="tableData.rows"
                v-model:isLoading="isLoading"
                v-on:page-change="onPageChange"
                v-on:sort-change="onSortChange"
                v-on:column-filter="onColumnFilter"
                v-on:per-page-change="onPerPageChange"
                v-on:row-click="onRowClick"
                theme="default"
                styleClass="vgt-table bordered"
                responsive
                compactMode
              >
                <template #table-row="props">
                  <div v-if="props.column.field === 'name'">
                    <div class="d-flex px-3 py-1">
                      <div v-if="props.row.photo?.url">
                        <a
                          target="_blank"
                          :href="props.row.photo?.url"
                          v-if="props.row.photo?.url"
                          class="me-3"
                        >
                          <img
                            class="clientImage"
                            alt="mac"
                            :src="props.row.photo.url"
                            width="40"
                          />
                        </a>
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <p class="text-xs font-weight-normal mb-0">
                          {{ props.row.firstName }} {{ props.row.lastName }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-if="props.column.field === 'phoneNumber'">
                    <span class="text-xs">{{ props.row.phoneNumber }}</span>
                  </div>

                  <div v-if="props.column.field === 'isConfirmed'">
                    <span
                      class="badge bg-gradient-success"
                      v-if="props.row.isConfirmed"
                      >Підтвердено</span
                    >
                    <span
                      class="badge bg-gradient-danger"
                      v-if="!props.row.isConfirmed"
                      >Не підтвердено</span
                    >
                  </div>

                  <div v-if="props.column.field === 'createdAt'">
                    <span class="my-2 text-xs">{{
                      timestampToString(props.row.createdAt)
                    }}</span>
                  </div>

                  <div v-if="props.column.field === 'lastVisitAt'">
                    <span class="my-2 text-xs">{{
                      timestampToString(props.row.lastVisitAt)
                    }}</span>
                  </div>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.clientImage {
  border-radius: 50%;
}

.filterName {
  padding-left: 4px;
}

.custom-view-close {
  margin: 10px;
}

/*.vgt-table {*/
/*  color: #7b809a !important;*/
/*  font-size: 0.75rem !important;*/
/*  text-align: center;*/
/*}*/

/*.vgt-table th {*/
/*  background: #FFFFFF !important;*/
/*  font-weight: bolder;*/
/*}*/
</style>

<script>
// import setTooltip from "@/assets/js/tooltip.js";
import { DateTime } from "luxon";
import Spinner from "@/views/shared/Spinner.vue";
import * as _ from "lodash";

import api from "@/services/api";
import constants from "@/constants";
import { useTable } from "@/composables/good-table";

export default {
  name: "clients-list",
  setup() {
    const { onRowClick } = useTable();

    return {
      onRowClick,
    };
  },

  components: {
    Spinner,
  },

  async created() {},

  beforeMount() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
  },

  async mounted() {
    await this.loadItems();
    this.dataLoaded = true;
    // setTimeout(setTooltip, 300);
  },

  data() {
    return {
      dataLoaded: false,
      tablePerPage: constants.defaultDatatablePerPage,

      showDevFeatures: false,

      tableData: {
        totalRecords: 0,
        rows: [],
      },
      isLoading: false,
      serverParams: {
        columnFilters: {
          // a map of column filters example: {name: 'john', age: '20'}
        },
        sort: [
          {
            field: "createdAt", // example: 'name'
            type: "desc", // 'asc' or 'desc'
          },
        ],

        page: 1,
        perPage: constants.defaultDatatablePerPage,
      },
      datatableColumns: [
        {
          label: "",
          field: "actions",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Імʼя Призвище",
          field: "name",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 filterName",
          tdClass: "text-xs font-weight-normal",
          filterOptions: {
            enabled: true,
            placeholder: "Пошук за ім'ям",
            // styleClass: "someClass",
            // filterFn: this.columnFilterFn,
            // trigger: 'enter',
          },
        },
        {
          label: "Телефон",
          field: "phoneNumber",
          type: "string",
          sortable: true,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
          tdClass: "text-xs font-weight-normal ",
          filterOptions: {
            enabled: true,
            placeholder: "Пошук за телефоном",
            // styleClass: "someClass",
            // filterFn: this.columnFilterFn,
            // trigger: 'enter',
          },
        },
        // {
        //   label: 'Статус телефона',
        //   field: 'isConfirmed',
        //   type: 'boolean',
        //   sortable: true,
        //   thClass: 'text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center',
        //   tdClass: 'text-xs font-weight-normal text-center',
        // },
        {
          label: "Дата реєстрації",
          field: "createdAt",
          sortable: true,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
          tdClass: "text-xs font-weight-normal",
        },
        // { // TODO: show this field once backend returns valid values (currently they are hardcoded)
        //   label: "Останній візит",
        //   field: "lastVisitAt",
        //   sortable: false,
        //   thClass:
        //     "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
        //   tdClass: "text-xs font-weight-normal ",
        // },
      ],

      customView: {
        metric: "",
        dateFrom: "",
        dateTo: "",
        period: "",
        staffId: "",
        positionId: "",
      },
    };
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    async onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      await this.loadItems();
    },

    async onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      await this.loadItems();
    },

    async onSortChange(params) {
      this.updateParams({
        page: 1,
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      await this.loadItems();
    },

    async onColumnFilter(params) {
      this.updateParams(params);
      await this.loadItems();
    },

    async loadItems() {
      const params = {
        limit: this.serverParams.perPage,
        offset: (this.serverParams.page - 1) * this.serverParams.perPage,

        sortField: _.get(this.serverParams, "sort[0].field"),
        sortDir: _.get(this.serverParams, "sort[0].type"),

        datatable: "y",
      };

      if (this.serverParams.columnFilters) {
        for (const [paramKey, paramValue] of Object.entries(
          this.serverParams.columnFilters
        )) {
          params[paramKey] = paramValue;
        }
      }

      if (this.$route.query.customView) {
        params.customView = this.$route.query.customView;
        params.dateFrom = this.$route.query.dateFrom;
        params.dateTo = this.$route.query.dateTo;
        if (this.$route.query.staffId) {
          params.staffId = this.$route.query.staffId;
        }
        if (this.$route.query.positionId) {
          params.positionId = this.$route.query.positionId;
        }

        this.customView.metric = this.$route.query.customView;
        this.customView.dateFrom = this.$route.query.dateFrom;
        this.customView.dateTo = this.$route.query.dateTo;

        this.customView.period = DateTime.fromISO(this.customView.dateFrom)
          .setLocale("uk")
          .toFormat("LLLL yyyy");
      }

      const result = await api.clients.list(params);
      this.tableData.totalRecords = result.totalRecords;
      this.tableData.rows = result.rows;
    },

    timestampToString(input) {
      if (!input) {
        return "-";
      }
      const datetime = DateTime.fromISO(input);
      return datetime
        .setLocale("uk-UA")
        .toLocaleString({ month: "long", day: "numeric", year: "numeric" });
    },

    async clearCustomViews() {
      await this.$router.push("/ecommerce/clients");
      this.customView = {};
      await this.loadItems();
    },
  },
};
</script>
