const defaultDatatablePerPage = 100;
const defaultPhoneCode = "+38"; // Ukraine

const hotelModeList = [
  "Oblaka",
  "hotel",
  "cedrus",
  "obuhovsky",
  "visia",
  "motel",
  "greenfirerelax",
  "perestanok",
  "minimalist",
  "example",
  "hatchyna",
  "mnmlstcampers",
  "medvino",
  "derevahotel",
  "skhovok",
  "hotel1",
  "kodaky-glamping",
  "amore",
  "zadinecka-dacha",
  "dacha96",
  "kuzhbei-eko-resort",
  "vilshanka",
  "art-vibe",
  "ecospace-sea",
  "concept-stay",
  "lestnica",
  "koshara",
  "pearl-of-vorokhta",
  "turhouse",
  "harmony-of-mountains",
  "prozori-bakota",
  "good-aframe",
  "bosohouses",
  "tbarn",
  "zakapelok",
  "smereka-house",
  "pokrova-prostir",
  "shalash",
  "under-sky",
  "solenoy-krystall",
  "skifian-oysterwill",
  "prichal80",
  "shatro",
  "prozori-tyligul",
  "theriverbirdsnest",
  "zdorovenki-buly-hotel",
  "solenoy-krystall",
  "SecretPlaceHome",
  "villa-diana",
  "time-for-rest",
  "dobriyhutor",
  "bulgary-house",
  "veres-hill-life",
  "paporoti-house",
  "mizhderev",
  "sadybauvani",
  "7nebo",
  "zelenytsya",
  "Hotel",
  "prozori-dobropark",
  "atmosphere",
  "family-evans",
  "pleso",
  "lisova-kazka",
  "bavky",
  "visia",
  "apar005",
  "soleneozero",
  "zakytok",
  "hyzhka-houses",
  "apt-oldmill",
  "dovbushhill",
  "rodosvit",
  "veronika",
  "ridge-apartments",
  "river-flow",
  "kruchi",
  "nebo",
  "mistsyna",
  "gofa",
  "grove-house",
  "kalyna-hostel",
  "fazenda-residence",
  "krylatyi-dim",
  "cloud-frame",
  "hata",
  "valley-hills",
  "petrick-house",
  "hutel-bed-and-camp",
];

const constants = {
  defaultDatatablePerPage,
  defaultPhoneCode,
  hotelModeList,
};

export default constants;
