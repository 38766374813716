import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { minutesToHours } from "@/utils/timeSlotConverter";

export const useCompanyStore = defineStore("company", () => {
  const company = useStorage("company", {});
  const branch = ref({});

  const isEmptyTenant = ref(false);

  const isPrepayment = computed(() => {
    if (!branch.value) {
      return false;
    }
    return (
      branch.value.paymentLiqPayEnabled || branch.value.paymentMonobankEnabled
    );
  });
  const companyName = computed(() => {
    return company.value?.name || "";
  });
  const companyIsHotel = computed(() => {
    return company.value?.crmMode === "hotel" ?? false;
  });

  function setBranch(data) {
    branch.value = data;
  }

  function getWorkingsHours(day = "") {
    let result = { start_time: 8, end_time: 20 };
    if (branch.value.workingHours) {
      if (!day) {
        const minDates = branch.value.workingHours.map((item) => item.from);
        let maxDates = branch.value.workingHours.map((item) => item.to);
        let maxDatesChanged = maxDates.map((item) => {
          if (item === 0) {
            return 24 * 60;
          }
          return item;
        });
        result.start_time = minutesToHours(Math.min(...minDates));
        result.end_time = minutesToHours(Math.max(...maxDatesChanged));
      } else {
        const dayHours = branch.value.workingHours.find(
          (item) => item.day === day
        );
        if (dayHours) {
          result.start_time = minutesToHours(dayHours.from);
          result.end_time = minutesToHours(dayHours.to || 24 * 60);
        }
      }
    }

    return result;
  }
  function setStatusTenant(status) {
    isEmptyTenant.value = status;
  }
  function setCompany(data) {
    company.value = data;
  }

  return {
    branch,
    company,
    isEmptyTenant,
    isPrepayment,
    companyIsHotel,
    companyName,
    setBranch,
    setCompany,
    setStatusTenant,
    getWorkingsHours,
  };
});
